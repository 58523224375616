<script>
import { navigateTo } from "svelte-router-spa";
import { form, bindClass } from "svelte-forms";
import axios from "axios";
import passwordValidator from "password-validator";
import FilePond, { registerPlugin } from "svelte-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { authToken } from "../../store/auth";
import { showErrorMessage, showSuccessMessage } from "../../utils/toast";
import { Navigate } from "svelte-router-spa";
import initPhoneValidator from "../../utils/phone-number";
import "intl-tel-input/build/css/intlTelInput.css";
import { userMobileCountryCode } from "../../store/auth";
import { parseAndShowErrorMessage } from "../../utils/errorParser";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const passwordValidatorSchema = new passwordValidator();
passwordValidatorSchema.is().min(8).is().max(50).has().not().spaces();

let baseApiURl = morrDashboard.env.API_URL;
let email = "";
let password = "";
let firstName = "";
let lastName = "";
let userName = "";
let mobileNumber = "";
let dob;
let agreePrivacyPolicy = false;
let image_url = "";
let country_code = "60";
let profile_pond;
let filepond_input_name = "filepond";
let confirmPassword = "";
let mobile_otp = "";
let email_otp = "";
let validationError = null;
let mobileNumberRef;
let mobileNumberInstance;
let isValidMobileNumber = true;

const weakPasswordValidation = function (value) {
  return {
    name: "weakPasswordValidation",
    valid: passwordValidatorSchema.validate(value),
  };
};

const signUpForm = form(
  () => ({
    email: { value: email, validators: ["required", "email"] },
    password: {
      value: password,
      validators: ["required", weakPasswordValidation],
    },
    agreePrivacyPolicy: {
      value: agreePrivacyPolicy,
      validators: ["required"],
    },
    firstName: {
      value: firstName,
      validators: ["required"],
    },
    lastName: {
      value: lastName,
      validators: ["required"],
    },
    userName: {
      value: userName,
      validators: [],
    },
    mobileNumber: {
      value: mobileNumber,
      validators: ["required"],
    },
    dob: {
      value: dob,
      validators: ["required"],
    },
    confirmPassword: {
      value: confirmPassword,
      validators: ["required"],
    },
    mobileOTP: {
      value: mobile_otp,
      validators: ["required"],
    },
    emailOTP: {
      value: email_otp,
      validators: ["required"],
    },
    image_url: {
      value: image_url,
    },
  }),
  {
    initCheck: false,
    validateOnChange: true,
  }
);

function performSignUp() {
  grecaptcha.ready(function () {
    grecaptcha
      .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
        action: "login",
      })
      .then(function (token) {
        if (token) {
          requestSignUpApi(token);
          increaseTime();
        }
      });
  });
}

function requestSignUpApi(captcha) {
  if (!email) {
    showErrorMessage("Enter Email!");
  } else if (!password) {
    showErrorMessage("Enter Password!");
  } else if (!confirmPassword) {
    showErrorMessage("Enter Confirm password!");
  } else if (confirmPassword !== password) {
    showErrorMessage("Cofirm password and Password should be same!");
    // } else if (!userName) {
    //   showErrorMessage('Enter a Username!');
  } else if (!mobileNumber) {
    showErrorMessage("Enter Mobile Number!");
  } else {
    let payload = {
      first_name: firstName,
      last_name: lastName,
      username: userName,
      email: email,
      country_code: country_code,
      mobile: mobileNumber,
      profile_image_url: image_url,
      password: password,
      confirm_password: confirmPassword,
      dob: dob,
    };
    axios
      .post(`${morrDashboard.env.API_URL}/users/manager/onboarding`, payload, {
        headers: {
          "x-google-token": captcha,
        },
      })
      .then((res) => {
        increaseFormValue();
        showSuccessMessage("Account successfully created, Please verify Email and Mobile Number");
      })
      .catch((err) => {
        parseAndShowErrorMessage(err);
        console.log("request failed", err.response);
      });
  }
}

function performNext() {
  let requiredFields = "";
  if (!firstName) {
    requiredFields = "First Name";
  }

  if (!lastName) {
    requiredFields = requiredFields + ", Last Name";
  }
  if (!dob) {
    requiredFields = requiredFields + ", Date of Birth ";
  }

  if (requiredFields) {
    showErrorMessage(`Please Enter ${requiredFields.replace(/(^,)|(,$)/g, "")}`);
  } else {
    increaseFormValue();
    initializeMobileNumberHandler();
  }
}

function performVerification() {
  grecaptcha.ready(function () {
    grecaptcha
      .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
        action: "login",
      })
      .then(function (token) {
        if (token) {
          requestVerificationApi(token);
        }
      });
  });
}

function requestVerificationApi(captcha) {
  if (!mobile_otp) {
    showErrorMessage("Enter Mobile OTP");
  } else if (!email_otp) {
    showErrorMessage("Enter Email OTP");
  } else if (!agreePrivacyPolicy) {
    showErrorMessage("Agree the Privacy Policy");
  } else {
    let payload = {
      email: email,
      mobile: mobileNumber,
      country_code: country_code,
      email_otp: email_otp,
      sms_otp: mobile_otp,
    };

    axios
      .post(`${morrDashboard.env.API_URL}/users/manager/onboarding/verification`, payload, {
        headers: {
          "x-google-token": captcha,
        },
      })
      .then((res) => {
        showSuccessMessage("Successfully verified details. You can login now");
        navigateTo("/auth/login");
      })
      .catch((err) => {
        console.log("Verification Error", err.response);
        parseAndShowErrorMessage(err);
      });
  }
}

function performResend() {
  grecaptcha.ready(function () {
    grecaptcha
      .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
        action: "login",
      })
      .then(function (token) {
        if (token) {
          requestVerificationResendApi(token);
          increaseTime();
        }
      });
  });
}

function requestVerificationResendApi(captcha) {
  let payload = {
    email: email,
    mobile: mobileNumber,
    country_code: country_code,
    email_otp: email_otp ? email_otp : 0,
    sms_otp: mobile_otp ? mobile_otp : 0,
  };

  axios
    .post(`${morrDashboard.env.API_URL}/users/manager/onboarding/resend`, payload, {
      headers: {
        "x-google-token": captcha,
      },
    })
    .then((res) => {
      showSuccessMessage("Successfully re-sent OTPs");
    })
    .catch((err) => {
      console.log("Verification Error", err.response);
      parseAndShowErrorMessage(err);
    });
}

let disabled;
$: disabled = true;

let time;
$: time = 1;

let timerID;

let disableTime;
$: disableTime = 1;

function timeCount() {
  timerID = setInterval(() => {
    if (time <= 0) {
      disabled = false;
      clearInterval(timerID);
      return;
    }
    time--;
  }, 1000);
}

function increaseTime() {
  disabled = true;
  time = disableTime * 60;
  disableTime++;
  timeCount();
}

let formValue;
$: formValue = 1;
function increaseFormValue() {
  formValue++;
}

function getFilePondServerConfig(image_for, image_type) {
  console.log("Auth Token for Image", $authToken);
  return {
    url: `${baseApiURl}/image`,
    process: {
      url: "/upload",
      method: "POST",
      withCredentials: false,
      timeout: 7000,
      ondata: (formdata) => {
        formdata.append("image_for", image_for);
        formdata.append("image_type", image_type);
        return formdata;
      },
      onload: (response) => {
        console.log(typeof response);
        response = JSON.parse(response);
        let file_url = response.data.file_url;
        image_url = file_url;
        return file_url;
      },
      onerror: (response) => {
        let err = JSON.parse(response);
        validationError = (err.hasOwnProperty("detail") && err.detail) || err;
        showErrorMessage(validationError);
        return err.detail;
      },
    },
    revert: (uniqueFileId, load, error) => {
      axios
        .delete(`${baseApiURl}/admin/images/${uniqueFileId}`, {
          headers: headers,
        })
        .then((res) => {
          image_url = "";
          load();
        })
        .catch((err) => {
          parseAndShowErrorMessage(err);
          console.error("FAILED_TO_REMOVE_IMAGE", err);
        });
    },
  };
}

function initializeMobileNumberHandler() {
  setTimeout(() => {
    mobileNumberInstance = initPhoneValidator(mobileNumberRef, $userMobileCountryCode);
  }, 500);
}
</script>

<div class="main-container">
  <div class="forms-container w-11/12 sm:10/12 md:w-9/12 lg:w-7/12 xl:w-6/12 shadow-lg rounded-xl">
    <div class="p-row w-full justify-evenly items-center border-b-2 border-gray h-14 shadow-sm">
      <div class="text-sm text-center font-semibold w-1/3 h-full py-4" class:active="{formValue === 1 || formValue === 2 || formValue === 3}">Personal Information</div>
      <div class="text-sm text-center font-semibold w-1/3 h-full py-4" class:active="{formValue === 2 || formValue === 3}">Contact Information</div>
      <div class="text-sm text-center font-semibold w-1/3 h-full py-4" class:active="{formValue === 3}">Verification</div>
    </div>
    {#if formValue === 1}
      <div class="form w-full">
        <h1 class="text-center font-bold text-xl my-4">Profile</h1>
        <div class="flex flex-col w-11/12 justify-center leading-relaxed">
          <div class="column-1">
            <div class="profile-image w-1/6">
              <FilePond required="{true}" bind:this="{profile_pond}" filepond_input_name="{filepond_input_name}" server="{getFilePondServerConfig('user', 'profile')}" allowMultiple="{false}" stylePanelLayout="compact circle" styleLoadIndicatorPosition="center bottom" />
            </div>
            <div class="w-10/12 relative z-0">
              <input class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200" type="text" placeholder=" " bind:value="{firstName}" />
              <label for="firstname" class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500 text-sm">
                <span>First Name</span>
              </label>
            </div>

            <div class="w-10/12 relative z-0 my-4">
              <input class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200" type="text" placeholder=" " bind:value="{lastName}" />
              <label for="lastname" class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500 text-sm">
                <span>Last Name</span>
              </label>
            </div>

            <div class="w-10/12 relative z-0 my-4">
              <input class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200" type="date" placeholder=" " bind:value="{dob}" data-date-format="YYYY MM DD" />
              <label for="dob" class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500 text-sm">
                <span>Date of birth</span>
              </label>
            </div>
          </div>
        </div>
        <div class="text-center w-11/12 my-3">
          <button class="btn w-10/12 morr-background rounded-3xl uppercase py-0 h-10 font-bold outline-none" on:click|preventDefault="{performNext}">Next</button>
        </div>
      </div>
    {:else if formValue === 2}
      <div class="form w-full px-10">
        <h1 class="text-center font-bold text-xl my-4">Credentials</h1>
        <div class="flex flex-col w-full">
          <div class="column-1 w-full leading-10 registration">
            <div class="w-10/12 relative z-2 my-4">
              <input
                class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                type="tel"
                placeholder=" "
                bind:value="{mobileNumber}"
                bind:this="{mobileNumberRef}"
                on:input="{() => {
                  isValidMobileNumber = mobileNumberInstance.isValidNumber();
                }}"
                on:change="{() => {
                  country_code = mobileNumberInstance.getSelectedCountryData().dialCode;
                  mobileNumberInstance.setNumber(mobileNumberInstance.getNumber());
                }}" />
              {#if !isValidMobileNumber && mobileNumber.length > 0}
                <div class="absolute right-0 duration-300 top-6 -z-1 origin-0 text-gray-500 text-sm text-red-500">
                  <span><i class="fas fa-times-circle"></i></span>
                </div>
              {:else if isValidMobileNumber && mobileNumber.length > 0}
                <div class="absolute right-0 duration-300 top-6 -z-1 origin-0 text-gray-500 text-sm text-green-500">
                  <span><i class="fas fa-check-circle"></i></span>
                </div>
              {/if}

              <label for="mobileNumber" class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500 text-sm">
                <span>Mobile Number</span>
              </label>
            </div>

            <input class="w-10/12 my-2" type="hidden" placeholder="Country Code" bind:value="{country_code}" />

            <div class="w-10/12 relative z-0 my-4">
              <input class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200" type="text" placeholder=" " bind:value="{email}" use:bindClass="{{ form: signUpForm, invalid: 'text-sm sm:text-base relative w-full border rounded placeholder-gray-400 focus:border-indigo-400 focus:outline-none py-2 pr-2 pl-12 border-red-500' }}" />
              <label for="email" class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500 text-sm">
                <span>Email</span>
              </label>
            </div>
          </div>
          <div class="column-2 w-full">
            <div class="w-10/12 relative z-0 my-4">
              <input class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200" type="password" placeholder=" " bind:value="{password}" use:bindClass="{{ form: signUpForm, invalid: 'border-red-500 focus:outline-none focus:border-red-500' }}" />
              <label for="password" class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500 text-sm">
                <span>Password</span>
              </label>
            </div>

            <div class="messages">
              {#if $signUpForm && $signUpForm.fields.password?.errors?.includes("weakPasswordValidation")}
                <p class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">* The password is weak, please choose a strong password</p>
              {/if}
            </div>

            <div class="w-10/12 relative z-0 my-4">
              <input class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200" type="password" placeholder=" " bind:value="{confirmPassword}" />
              <label for="confirmPassword" class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500 text-sm">
                <span>Confirm Password</span>
              </label>
            </div>
          </div>
        </div>
        <div class="text-center w-full my-4">
          <button class="btn w-10/12 morr-background rounded-3xl uppercase py-0 h-10 font-bold outline-none" on:click|preventDefault="{performSignUp}">Next</button>
        </div>
      </div>
    {:else}
      <div class="form w-full">
        <h1 class="text-center font-bold text-xl my-4">Verification</h1>
        <div class="w-11/12 leading-9">
          <div class="column-1">
            <div class="w-10/12 relative z-0 my-4">
              <input class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200" type="text" placeholder=" " bind:value="{mobile_otp}" />
              <label for="mobile_otp" class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500 text-sm">
                <span>Mobile OTP</span>
              </label>
            </div>
            <div class="w-10/12 relative z-0 my-4">
              <input class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200" type="text" placeholder=" " bind:value="{email_otp}" />
              <label for="email_otp" class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500 text-sm">
                <span>Email OTP</span>
              </label>
            </div>

            <div class="form-group text-left mt-2">
              <input type="checkbox" id="agreePolicy" bind:checked="{agreePrivacyPolicy}" />
              <label for="agreePolicy"
                >I agree with the
                <a href="https://morr.my/privacy-policy" target="_blank" no-follow class="text-red-500"> Privacy Policy </a></label>
            </div>
          </div>
        </div>
        <div class="text-center w-full my-4 flex-col justify-evenly">
          <button class="w-5/12 morr-background rounded-3xl uppercase py-0 h-10 font-bold outline-none" on:click|preventDefault="{performVerification}">Register</button>
          <button class="{`w-5/12 morr-background-light border-2 rounded-3xl border-opacity-10 border-yellow-600 py-0 h-10 font-bold outline-none ${time !== 0 ? 'opacity-40' : 'opacity-100'}`}" disabled="{disabled}" on:click|preventDefault="{performResend}">
            {#if time !== 0}
              {`Resend in ${time}`}
            {:else}
              {`Resend OTPs`}
            {/if}
          </button>
        </div>
      </div>
    {/if}
    <div class="flex relative my-4 w-full z-2 justify-between px-24 pb-4 text-lg font-normal text-black">
      <div class="space-x-2 inline-block">
        <Navigate to="/auth/forgot-password">
          <small>forgot password?</small>
        </Navigate>
      </div>

      <div class="space-x-2 inline-block">
        <Navigate to="/auth/login">
          <small>already have an account?</small>
        </Navigate>
      </div>
    </div>
  </div>
</div>

<style windi:inject>
:global(.registration .iti) {
  border-bottom: 1px solid #b3b1b140;
}
:global(.registration .iti--separate-dial-code .iti__selected-flag) {
  background-color: transparent !important;
}
:global(.registration .iti__country-list) {
  max-height: 300px !important;
  z-index: 9 !important;
}
:global(.registration .intl-tel-input .flag-container) {
  position: static;
}
:global(.registration .intl-tel-input .selected-flag) {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
}
:global(.registration .intl-tel-input .country-list) {
  width: 100%;
  top: 100%;
}
:global(.filepond--drop-label) {
  background-color: bisque !important;
  font-size: 0.8rem !important;
}
.no-border-bottom {
  border-bottom: none !important;
}
.main-container {
  display: flex;
  justify-content: center;
}
.p-row {
  display: flex;
  position: relative;
  align-items: center;
}
.messages {
  display: flex;
  justify-content: flex-start;
}
.active {
  color: #e8ad90e0;
  background-color: #3c3c3c;
  transition: 0.4s;
}
.active::after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  right: -20px;
  top: 0px;
  border-left: 20px solid rgb(37, 36, 36);
}
.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: 0.3s;
}
.form input {
  outline: none;
  border: none;
  border-bottom: 1px solid #b3b1b140;
  background: transparent;
  background-clip: content-box;
}
.column-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.column-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.forms-container {
  background-color: #f5f1ea;
  overflow: hidden;
  z-index: 2;
}
.form-group {
  display: block;
}
.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.form-group label {
  position: relative;
  cursor: pointer;
}
.form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #e8ad90e0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}
.form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #e8ad90e0;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.navigation {
  display: flex;
  justify-content: center;
  padding: 5px;
  z-index: 2;
}
.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.appearance-none {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.bg-transparent {
  background-color: transparent;
}
.focus\:border-black:focus {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}
.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.focus\:border-indigo-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity));
}
.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}
.focus\:border-red-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}
.border-yellow-600 {
  --tw-border-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity));
}
.border-opacity-10 {
  --tw-border-opacity: 0.1;
}
.rounded-xl {
  border-radius: 0.75rem;
}
.rounded-3xl {
  border-radius: 1.5rem;
}
.rounded {
  border-radius: 0.25rem;
}
.border-0 {
  border-width: 0px;
}
.border {
  border-width: 1px;
}
.border-2 {
  border-width: 2px;
}
.border-b-2 {
  border-bottom-width: 2px;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.justify-evenly {
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  -webkit-justify-content: space-evenly;
  justify-content: space-evenly;
}
.font-semibold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}
.font-medium {
  font-weight: 500;
}
.font-normal {
  font-weight: 400;
}
.h-14 {
  height: 3.5rem;
}
.h-full {
  height: 100%;
}
.h-10 {
  height: 2.5rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.leading-relaxed {
  line-height: 1.625;
}
.leading-10 {
  line-height: 2.5rem;
}
.leading-9 {
  line-height: 2.25rem;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.mt-0 {
  margin-top: 0px;
}
.mt-1 {
  margin-top: 0.25rem;
}
.ml-1 {
  margin-left: 0.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.opacity-40 {
  opacity: 0.4;
}
.opacity-100 {
  opacity: 1;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.px-24 {
  padding-left: 6rem;
  padding-right: 6rem;
}
.pt-3 {
  padding-top: 0.75rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.pl-12 {
  padding-left: 3rem;
}
.pb-4 {
  padding-bottom: 1rem;
}
.placeholder-gray-400::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.top-3 {
  top: 0.75rem;
}
.right-0 {
  right: 0px;
}
.top-6 {
  top: 1.5rem;
}
.shadow-lg {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-sm {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}
.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.tracking-wide {
  letter-spacing: 0.025em;
}
.w-11\/12 {
  width: 91.666667%;
}
.w-full {
  width: 100%;
}
.w-1\/3 {
  width: 33.333333%;
}
.w-1\/6 {
  width: 16.666667%;
}
.w-10\/12 {
  width: 83.333333%;
}
.w-5\/12 {
  width: 41.666667%;
}
.z-0 {
  z-index: 0;
}
.-z-1 {
  z-index: -1;
}
.z-2 {
  z-index: 2;
}
.duration-300 {
  -webkit-transition-duration: 300ms;
  -o-transition-duration: 300ms;
  transition-duration: 300ms;
}
@media (min-width: 640px) {
  .invalid\:\ sm\:text-base:invalid {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
@media (min-width: 768px) {
  .md\:w-9\/12 {
    width: 75%;
  }
}
@media (min-width: 1024px) {
  .lg\:w-7\/12 {
    width: 58.333333%;
  }
}
@media (min-width: 1280px) {
  .xl\:w-6\/12 {
    width: 50%;
  }
}
</style>
