<script>
import { Navigate } from "svelte-router-spa";
import { form, bindClass } from "svelte-forms";
import axios from "axios";
import { showSuccessMessage } from "../../utils/toast";
import { parseAndShowErrorMessage } from "../../utils/errorParser";

let email = "";

const forgotPasswordForm = form(
  () => ({
    email: { value: email, validators: ["required", "email"] },
  }),
  {
    initCheck: false,
    validateOnChange: true,
  }
);

function requestResetPasswordApi(googleToken) {
  let payload = {
    email,
  };

  axios
    .post(`${morrDashboard.env.API_URL}/users/forgot-password`, payload, {
      headers: {
        "x-google-token": googleToken,
        "x-platform": "",
      },
    })
    .then((res) => {
      console.log("LOGGING_IN", res);
      showSuccessMessage(res.data.message);
    })
    .catch((err) => {
      console.log("request failed", err.response);
      parseAndShowErrorMessage(err);
    });
}

function sendResetPasswordLink() {
  forgotPasswordForm.validate();

  grecaptcha.ready(function () {
    grecaptcha
      .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
        action: "ForgotPassword",
      })
      .then(function (token) {
        if (token) {
          requestResetPasswordApi(token);
        }
      });
  });
}
</script>

<div class="main-container">
  <div class="w-11/12 sm:10/12 md:w-9/12 lg:w-7/12 xl:w-6/12">
    <form class="forms-container form w-full p-8 leading-10 rounded-xl filter drop-shadow-md">
      <img class="w-16 my-6" src="https://morrhq.s3.ap-south-1.amazonaws.com/morr-320x.png" alt="Welcome to Morr" title="Welcome to Morr" />
      <h1 class="text-center font-bold text-xl">Forgot password</h1>
      <input type="hidden" id="g-recaptcha-response" name="g-recaptcha-response" />
      <input id="grid-email" type="email" class="w-10/12 my-2" placeholder="Email" bind:value="{email}" use:bindClass="{{ form: forgotPasswordForm, invalid: 'text-sm sm:text-base relative w-10/12 my-2 border rounded placeholder-gray-400 focus:border-indigo-400 focus:outline-none py-2 pr-2 pl-12 border-red-500' }}" />
      {#if $forgotPasswordForm && $forgotPasswordForm.fields.email?.errors?.includes("required")}
        <div class="messages w-10/12">
          <p class="flex font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">* The email is required</p>
        </div>
      {/if}
      {#if $forgotPasswordForm && $forgotPasswordForm.fields.email?.errors?.includes("email")}
        <div class="messages w-10/12">
          <p class="flex font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">* The email is invalid!</p>
        </div>
      {/if}

      <div class="text-center w-full my-3">
        <button class="btn w-10/12 morr-background rounded-3xl uppercase py-0 h-10 font-bold outline-none" type="button" on:click="{() => sendResetPasswordLink()}" disabled="{!$forgotPasswordForm.valid}"> Email link </button>
      </div>
      <div class="flex relative mt-4 w-full z-2 justify-between px-16 text-lg font-normal text-black">
        <div class="space-x-2 inline-block">
          <Navigate to="/auth/login">
            <small>remember the password?</small>
          </Navigate>
        </div>
        <div class="space-x-2 inline-block">
          <Navigate to="/auth/register">
            <small>Create new account</small>
          </Navigate>
        </div>
      </div>
    </form>
  </div>
</div>

<style windi:inject>
.messages {
  display: flex;
  justify-content: flex-start;
}
.main-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.check {
  display: flex;
  justify-content: flex-start;
}
.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.forms-container {
  background-color: #f5f1ea;
  border-radius: 10px;
  overflow: hidden;
  z-index: 2;
}
.form input {
  outline: none;
  border: none;
  border-bottom: 1px solid #b3b1b140;
  background: transparent;
}
.form-group {
  display: block;
}
.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.form-group label {
  position: relative;
  cursor: pointer;
}
.form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #e8ad90e0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}
.form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #e8ad90e0;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.focus\:border-indigo-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity));
}
.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}
.rounded-xl {
  border-radius: 0.75rem;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-3xl {
  border-radius: 1.5rem;
}
.border {
  border-width: 1px;
}
.inline-block {
  display: inline-block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.font-bold {
  font-weight: 700;
}
.font-medium {
  font-weight: 500;
}
.font-normal {
  font-weight: 400;
}
.h-10 {
  height: 2.5rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.invalid\:\ text-sm:invalid {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.leading-10 {
  line-height: 2.5rem;
}
.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.mt-1 {
  margin-top: 0.25rem;
}
.ml-1 {
  margin-left: 0.25rem;
}
.mt-4 {
  margin-top: 1rem;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.p-8 {
  padding: 2rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.pl-12 {
  padding-left: 3rem;
}
.placeholder-gray-400::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.relative {
  position: relative;
}
.text-center {
  text-align: center;
}
.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.tracking-wide {
  letter-spacing: 0.025em;
}
.w-11\/12 {
  width: 91.666667%;
}
.w-full {
  width: 100%;
}
.w-16 {
  width: 4rem;
}
.w-10\/12 {
  width: 83.333333%;
}
.z-2 {
  z-index: 2;
}
.filter {
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
}
@media (min-width: 640px) {
  .sm\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
@media (min-width: 768px) {
  .md\:w-9\/12 {
    width: 75%;
  }
}
@media (min-width: 1024px) {
  .lg\:w-7\/12 {
    width: 58.333333%;
  }
}
@media (min-width: 1280px) {
  .xl\:w-6\/12 {
    width: 50%;
  }
}
</style>
