<script>
import Carousel from "@beyonk/svelte-carousel";
import { ChevronLeftIcon, ChevronRightIcon } from "svelte-feather-icons";
import QrCode from "svelte-qrcode";

export let merchant;
export let transactionStats;

function generateQRCodeJSON(merchant) {
  let merchantData = {
    merchantUid: merchant.merchant_id,
    merchantPageUri: "https://app.morr.my/tabs/payments/pay/" + merchant.merchant_id,
    playStore: "https://play.google.com/store/apps/details?id=io.morr.deals",
    appStore: "https://apps.apple.com/my/app/morr/id1544728152",
  };
  return JSON.stringify(merchantData);
}
</script>

<div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-16">
  <div class="px-6">
    <div class="flex flex-wrap justify-center">
      <div class="w-full px-4 flex justify-center ring-2 ring-offset-gray-50">
        <div class="relative">
          <img alt="{merchant.name}" title="{merchant.name}" src="{merchant.images?.profile?.length > 0 ? merchant.images.profile[0] : 'https://app.morr.my/assets/icon/morr-white-transparent.png'}" class="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-21 lg: max-w-150-px" style="background-color: #e5a68c;" />
        </div>
      </div>

      {#if transactionStats}
        <div class="w-full px-4 text-center mt-20">
          <div class="flex justify-center py-4 lg:pt-4 pt-8">
            <div class="mr-4 p-3 text-center">
              <span class="text-xl font-bold block uppercase tracking-wide text-gray-700">
                {transactionStats?.total || 0}
              </span>
              <span class="text-sm text-gray-500">Total Transactions count </span>
            </div>
            <div class="mr-4 p-3 text-center">
              <span class="text-xl font-bold block uppercase tracking-wide text-gray-700">
                {transactionStats?.success || 0}
              </span>
              <span class="text-sm text-gray-500">Successful Transactions</span>
            </div>
            <div class="lg:mr-4 p-3 text-center">
              <span class="text-xl font-bold block uppercase tracking-wide text-gray-700">
                RM {transactionStats?.amount_earned || 0}
              </span>
              <span class="text-sm text-gray-500">Total Earnings</span>
            </div>
          </div>
        </div>
      {/if}
    </div>
    <div class="text-center mt-12">
      <div class="mx-auto justify-center">
        <QrCode value="{generateQRCodeJSON(merchant)}" style="margin: 0 auto" />
      </div>

      <h3 class="text-xl font-semibold leading-normal mb-2 text-gray-800 mb-2">
        {merchant.name}
      </h3>
      <div class="text-sm leading-normal mt-0 mb-2 text-gray-500 font-bold uppercase">
        <a target="_blank" href="{merchant.address?.google_map_link}" rel="nourlopener noreferrer">
          <i class="fas fa-map-marker-alt mr-2 text-lg text-gray-500"></i>
          {merchant.address?.city}, {merchant.address?.country}
        </a>
      </div>
    </div>
    <div class="mt-10 py-10 border-t border-gray-300 text-center">
      <div class="flex flex-wrap justify-center">
        {#if merchant.images?.portrait?.length > 0}
          <div class="w-full">
            <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase text-left">Portraits (Recent Images)</h6>
            <Carousel class="w-11/12" perPage="4">
              <span class="control" slot="left-control">
                <ChevronLeftIcon />
              </span>

              {#each merchant.images.portrait as src, imageIndex (src)}
                <div class="slide-content p-2">
                  <img src="{src}" alt="nature" />
                </div>
              {/each}

              <span class="control" slot="right-control">
                <ChevronRightIcon />
              </span>
            </Carousel>
          </div>
        {/if}

        {#if merchant.images?.banners?.length > 0}
          <div class="w-full">
            <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase text-left">Banners</h6>
            <Carousel class="w-11/12" perPage="2">
              <span class="control" slot="left-control">
                <ChevronLeftIcon />
              </span>

              {#each merchant.images.banners as src, imageIndex (src)}
                <div class="slide-content p-2">
                  <img src="{src}" alt="{merchant.name}" title="{merchant.name}" />
                </div>
              {/each}

              <span class="control" slot="right-control">
                <ChevronRightIcon />
              </span>
            </Carousel>
          </div>
        {/if}
      </div>
    </div>

    <div class="mt-10 py-10 border-t border-gray-300 text-left">
      <div class="flex flex-wrap justify-between">
        <div class="w-4/12">
          <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">Contact Numbers</h6>

          <div class="w-full">
            {#each merchant.contact_numbers as contact, contactIndex (contact)}
              <div class="text-sm leading-normal mt-0 mb-2 text-gray-500 font-bold uppercase">
                <i class="fas fa-phone mr-2 text-lg text-gray-500"></i>
                +{contact.country_code} - {contact.phone_number}
                {#if contact.is_whatsapp_contact}
                  <i class="fab fa-whatsapp"></i>
                {/if}
              </div>
            {/each}
          </div>
        </div>

        <div class="w-4/12">
          <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">Schedule</h6>
          <div class="w-full">
            {#each merchant.schedule as scheduler, scheduleIndex (scheduler)}
              <div class="text-sm leading-normal mt-0 mb-2 text-gray-500 font-bold uppercase">
                <i class="fas fa-clock mr-2 text-lg text-gray-500"></i>
                {scheduler.start} - {scheduler.end}
              </div>
            {/each}
          </div>
        </div>

        <div class="w-4/12">
          <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">Discount Tiers</h6>
          <div class="w-full">
            {#each Object.entries(merchant.point_tiers) as [tier_name, tier_info]}
              <div class="{`text-sm leading-normal mt-0 mb-2 text-gray-500 font-bold uppercase ${tier_name}`}">
                <i class="fas fa-money-bill-alt mr-2 text-lg text-gray-500"></i>
                {tier_name} | {tier_info.thresold} | {tier_info.type} | {tier_info.increment_by}
              </div>
            {/each}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<style windi:inject>
:global(.qrcode) {
  margin: 0 auto !important;
}
.bronze {
  color: #cd7f32;
}
.silver {
  color: #c0c0c0;
}
.gold {
  color: #ffd700;
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-full {
  border-radius: 9999px;
}
.border-none {
  border-style: none;
}
.border-t {
  border-top-width: 1px;
}
.block {
  display: block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.font-bold {
  font-weight: 700;
}
.font-semibold {
  font-weight: 600;
}
.h-auto {
  height: auto;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.leading-normal {
  line-height: 1.5;
}
.-m-16 {
  margin: -4rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mt-16 {
  margin-top: 4rem;
}
.-ml-21 {
  margin-left: -5.25rem;
}
.mt-20 {
  margin-top: 5rem;
}
.mr-4 {
  margin-right: 1rem;
}
.mt-12 {
  margin-top: 3rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mt-0 {
  margin-top: 0px;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mt-10 {
  margin-top: 2.5rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.min-w-0 {
  min-width: 0px;
}
.p-3 {
  padding: 0.75rem;
}
.p-2 {
  padding: 0.5rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.pt-8 {
  padding-top: 2rem;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.shadow-xl {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 20px 25px -5px rgba(var(--tw-shadow-color), 0.1), 0 10px 10px -5px rgba(var(--tw-shadow-color), 0.04);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.ring-offset-gray-50 {
  --tw-ring-offset-opacity: 1;
  --tw-ring-offset-color: rgba(249, 250, 251, var(--tw-ring-offset-opacity));
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.tracking-wide {
  letter-spacing: 0.025em;
}
.align-middle {
  vertical-align: middle;
}
.break-words {
  overflow-wrap: break-word;
}
.w-full {
  width: 100%;
}
.w-11\/12 {
  width: 91.666667%;
}
.w-4\/12 {
  width: 33.333333%;
}
@media (min-width: 1024px) {
  .lg\:mr-4 {
    margin-right: 1rem;
  }
  .lg\:pt-4 {
    padding-top: 1rem;
  }
}
</style>
