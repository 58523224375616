<script>
import { Navigate, routeIsActive } from "svelte-router-spa";
import UserDropdown from "components/Dropdowns/UserDropdown.svelte";
import { isSuperAdmin } from "../../store/admin";

let collapseShow = "hidden";

function toggleCollapseShow(classes) {
  collapseShow = classes;
}
</script>

<nav class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-no-wrap md:overflow-hidden shadow-xl flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6 text-white" style="background-color:#e8ad91; color: #fff;">
  <div class="md:flex-col md:items-stretch md:min-h-full md:flex-no-wrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
    <button class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent" type="button" on:click="{() => toggleCollapseShow('morr-background-white m-2 py-3 px-6')}">
      <i class="fas fa-bars"></i>
    </button>

    <a class=" md:block md:pb-2 text-gray-100 mr-0 inline-block justify-center text-center whitespace-no-wrap text-sm uppercase font-bold p-4 px-0" href="/">
      <img class="w-24 my-6 container mx-auto" src="https://morrhq.s3.ap-south-1.amazonaws.com/morr-white-transparent.png" alt="Welcome to Morr" title="Welcome to Morr" />
    </a>

    <ul class="md:hidden items-center flex flex-wrap list-none">
      <li class="inline-block relative">
        <UserDropdown />
      </li>
    </ul>

    <div class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded {collapseShow}">
      <div class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-gray-300">
        <div class="flex flex-wrap">
          <div class="w-6/12">
            <a class="md:block text-left md:pb-2 text-gray-100 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0" href="/">
              <img class="w-16 my-6" src="https://morrhq.s3.ap-south-1.amazonaws.com/morr-320x.png" alt="Welcome to Morr" title="Welcome to Morr" />
            </a>
          </div>
          <div class="w-6/12 flex justify-end">
            <button type="button" class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent" on:click="{() => toggleCollapseShow('hidden')}">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>

      <form class="mt-6 mb-4 md:hidden">
        <div class="mb-3 pt-0">
          <input type="text" placeholder="Search" class="px-3 py-2 h-12 border border-solid border-gray-600 placeholder-gray-400 text-gray-100 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal" />
        </div>
      </form>

      <hr class="my-4 md:min-w-full" />

      <h6 class="md:min-w-full text-gray-600 text-sm uppercase font-bold block pt-1 pb-4 no-underline">Dashboard</h6>

      <ul class="md:flex-col md:min-w-full flex flex-col list-none">
        <li class="items-center">
          <Navigate to="/merchants/dashboard" styles="text-sm capitalize py-3 font-bold block">
            <i class="fas fa-home mr-2 text-sm " class:active="{routeIsActive('/merchants/dashboard')}"></i>
            Home
          </Navigate>
        </li>
      </ul>

      <hr class="my-4 md:min-w-full" />

      <h6 class="md:min-w-full text-gray-600 text-sm uppercase font-bold block pt-1 pb-4 no-underline">Merchants</h6>

      <ul class="md:flex-col md:min-w-full flex flex-col list-none">
        <li class="items-center">
          <Navigate to="/merchants/list" styles="text-sm capitalize py-3 font-bold block">
            <i class="fas fa-list mr-2 text-sm" class:active="{routeIsActive('/merchants/list')}"></i>
            List Merchants
          </Navigate>
        </li>
        <li class="items-center">
          <Navigate to="/merchants/create" styles="text-sm capitalize py-3 font-bold block">
            <i class="fas fa-store-alt mr-2 text-sm" class:active="{routeIsActive('/merchants/create')}"></i>
            Add Merchant
          </Navigate>
        </li>
        <li class="items-center">
          <Navigate to="/merchants/managers/add" styles="text-sm capitalize py-3 font-bold block">
            <i class="fas fa-user-shield mr-2 text-sm" class:active="{routeIsActive('/merchants/managers/add')}"></i>
            Add Manager
          </Navigate>
        </li>
      </ul>

      <hr class="my-4 md:min-w-full" />

      <h6 class="md:min-w-full text-gray-600 text-sm uppercase font-bold block pt-1 pb-4 no-underline">Transactions</h6>

      <ul class="md:flex-col md:min-w-full flex flex-col list-none">
        <li class="items-center">
          <Navigate to="/payments/cash-pay-direct" styles="text-sm capitalize py-3 font-bold block">
            <i class="fas fa-money-bill-alt mr-2 text-sm" class:active="{routeIsActive('/payments/cash-pay-direct')}"></i>
            Approve Cash
          </Navigate>
        </li>
        <li class="items-center">
          <Navigate to="/payments/transactions/history" styles="text-sm capitalize py-3 font-bold block">
            <i class="fas fa-receipt mr-2 text-sm" class:active="{routeIsActive('/payments/transactions/history')}"></i>
            History
          </Navigate>
        </li>
      </ul>

      <hr class="my-4 md:min-w-full" />

      <h6 class="md:min-w-full text-gray-600 text-sm uppercase font-bold block pt-1 pb-4 no-underline">BRANDS</h6>

      <ul class="md:flex-col md:min-w-full flex flex-col list-none">
        <li class="items-center">
          <Navigate to="/merchants/brands/list" styles="text-sm capitalize py-3 font-bold block">
            <i class="fas fa-list mr-2 text-sm" class:active="{routeIsActive('/merchants/brands')}"></i>
            List Brands
          </Navigate>
        </li>
        <li class="items-center">
          <Navigate to="/merchants/brands/create" styles="text-sm capitalize py-3 font-bold block">
            <i class="fas fa-store-alt mr-2 text-sm" class:active="{routeIsActive('/merchants/create/brand')}"></i>
            Add Brand
          </Navigate>
        </li>
        <li class="items-center">
          <Navigate to="/merchants/access/merchant" styles="text-sm capitalize py-3 font-bold block">
            <i class="fas fa-user-shield mr-2 text-sm" class:active="{routeIsActive('/merchants/access/merchant')}"></i>
            Merchant Access
          </Navigate>
        </li>
      </ul>
      {#if $isSuperAdmin}
        <hr class="my-4 md:min-w-full" />

        <h6 class="md:min-w-full text-gray-600 text-sm uppercase font-bold block pt-1 pb-4 no-underline">EMAIL TEMPLATES</h6>

        <ul class="md:flex-col md:min-w-full flex flex-col list-none">
          <li class="items-center">
            <Navigate to="/admin/email-template/list" styles="text-sm capitalize py-3 font-bold block">
              <i class="fas fa-list mr-2 text-sm" class:active="{routeIsActive('/admin/email-template/list')}"></i>
              List
            </Navigate>
          </li>
          <li class="items-center">
            <Navigate to="/admin/email-template/create" styles="text-sm capitalize py-3 font-bold block">
              <i class="fas fa-envelope-open-text mr-2 text-sm" class:active="{routeIsActive('/admin/email-template/create')}"></i>
              Add New
            </Navigate>
          </li>
        </ul>
      {/if}
      {#if $isSuperAdmin}
        <hr class="my-4 md:min-w-full" />

        <h6 class="md:min-w-full text-gray-600 text-sm uppercase font-bold block pt-1 pb-4 no-underline">App Home Page</h6>

        <ul class="md:flex-col md:min-w-full flex flex-col list-none">
          <li class="items-center">
            <Navigate to="/admin/homepage/rows" styles="text-sm capitalize py-3 font-bold block">
              <i class="fas fa-list mr-2 text-sm" class:active="{routeIsActive('/admin/homepage/rows')}"></i>
              Rows
            </Navigate>
            <Navigate to="/admin/homepage/promobanners" styles="text-sm capitalize py-3 font-bold block">
              <i class="fas fa-list mr-2 text-sm" class:active="{routeIsActive('/admin/homepage/promobanners')}"></i>
              Promo Banners
            </Navigate>
            <Navigate to="/admin/homepage/quicklinks" styles="text-sm capitalize py-3 font-bold block">
              <i class="fas fa-list mr-2 text-sm" class:active="{routeIsActive('/admin/homepage/quicklinks')}"></i>
              Quick Links
            </Navigate>
          </li>
        </ul>
      {/if}
    </div>
  </div>
</nav>

<style windi:inject>
@import "https://unpkg.com/@responsive-ui/accordion@latest/lib/index.css";
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.bg-transparent {
  background-color: transparent;
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.border-transparent {
  border-color: transparent;
}
.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}
.border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.rounded {
  border-radius: 0.25rem;
}
.border-solid {
  border-style: solid;
}
.border {
  border-width: 1px;
}
.border-b {
  border-bottom-width: 1px;
}
.cursor-pointer {
  cursor: pointer;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.flex-1 {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
}
.font-bold {
  font-weight: 700;
}
.font-normal {
  font-weight: 400;
}
.h-auto {
  height: auto;
}
.h-12 {
  height: 3rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.leading-none {
  line-height: 1;
}
.leading-snug {
  line-height: 1.375;
}
.list-none {
  list-style-type: none;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.mr-0 {
  margin-right: 0px;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.opacity-50 {
  opacity: 0.5;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.p-4 {
  padding: 1rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.pb-4 {
  padding-bottom: 1rem;
}
.pt-0 {
  padding-top: 0px;
}
.pt-1 {
  padding-top: 0.25rem;
}
.placeholder-gray-400::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.top-0 {
  top: 0px;
}
.left-0 {
  left: 0px;
}
.right-0 {
  right: 0px;
}
.shadow-xl {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 20px 25px -5px rgba(var(--tw-shadow-color), 0.1), 0 10px 10px -5px rgba(var(--tw-shadow-color), 0.04);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 3px 0 rgba(var(--tw-shadow-color), 0.1), 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-none {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: none;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.no-underline {
  text-decoration: none;
}
.w-full {
  width: 100%;
}
.w-24 {
  width: 6rem;
}
.w-6\/12 {
  width: 50%;
}
.w-16 {
  width: 4rem;
}
.z-10 {
  z-index: 10;
}
.z-40 {
  z-index: 40;
}
@media (min-width: 768px) {
  .md\:block {
    display: block;
  }
  .md\:flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
  .md\:hidden {
    display: none;
  }
  .md\:flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
  .md\:flex-col {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  .md\:items-stretch {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    -webkit-align-items: stretch;
    align-items: stretch;
  }
  .md\:mt-4 {
    margin-top: 1rem;
  }
  .md\:min-h-full {
    min-height: 100%;
  }
  .md\:min-w-full {
    min-width: 100%;
  }
  .md\:opacity-100 {
    opacity: 1;
  }
  .md\:overflow-hidden {
    overflow: hidden;
  }
  .md\:overflow-y-auto {
    overflow-y: auto;
  }
  .md\:pb-2 {
    padding-bottom: 0.5rem;
  }
  .md\:fixed {
    position: fixed;
  }
  .md\:relative {
    position: relative;
  }
  .md\:left-0 {
    left: 0px;
  }
  .md\:top-0 {
    top: 0px;
  }
  .md\:bottom-0 {
    bottom: 0px;
  }
  .md\:shadow-none {
    --tw-shadow-color: 0, 0, 0;
    --tw-shadow: none;
    -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .md\:w-64 {
    width: 16rem;
  }
}
</style>
