<script>
import { onMount } from "svelte";
import { Navigate } from "svelte-router-spa";
import { navigateTo } from "svelte-router-spa";
import { form, bindClass } from "svelte-forms";
import axios from "axios";
import { showSuccessMessage, showErrorMessage } from "../../utils/toast";
import setAuthCreds from "../../utils/auth";

import initPhoneValidator from "../../utils/phone-number";
import { parseAndShowErrorMessage } from "../../utils/errorParser";

let code = null;
let username = "";
let password = "";
let rememberMe = false;
let isEmail = true;
let isLoginScreen = true;
let isOTPScreen = false;
let otp;
let otpResendCountDown = 0;
let isValidMobileNumber = true;

let emailMobileHandlerElement;
let emailMobileHandlerInstance;

$: emailMobileHandlerElement;

let isMounted = false;

let showFormValidationError = {};

$: if (username && isMounted) {
  let selectedCountryCode = code ? code : emailMobileHandlerInstance.getSelectedCountryData().dialCode;
  emailMobileHandlerInstance.setNumber(`+${selectedCountryCode}${username}`);
}

let loginForm = form(
  () => ({
    username: { value: username, validators: ["required"] },
    password: { value: password, validators: ["required"] },
  }),
  {
    initCheck: true,
    validateOnChange: true,
  }
);

let otpForm = form(
  () => ({
    otp: { value: otp, validators: ["required"] },
  }),
  {
    initCheck: true,
    validateOnChange: true,
  }
);

function initializeCountDown() {
  otpResendCountDown = 60;
  let countDown = setInterval(() => {
    if (otpResendCountDown > 0) {
      otpResendCountDown--;
    } else {
      clearInterval(countDown);
    }
  }, 1000);
}

function requestLoginApi(googleRecaptchaToken) {
  let payload = {
    form_data: {
      code,
      username,

      password,
      is_email: isEmail,
    },
    captcha: {
      captcha_token: googleRecaptchaToken,
    },
  };

  axios
    .post(`${morrDashboard.env.API_URL}/users/omni-login`, payload)
    .then((res) => {
      console.log("LOGGING_IN", res);

      setAuthCreds(true, res.data.access_token || "", res.data.refresh_token || "");

      showSuccessMessage("Successfully logged in");

      navigateTo("/merchants/dashboard");
    })
    .catch((err) => {
      console.log("request failed", err.response);
      setAuthCreds(false, "", "");
      parseAndShowErrorMessage(err);
    });
}

function requestLoginOTP(googleRecaptchaToken) {
  let payload = {
    code,
    mobile: username,
  };

  axios
    .post(`${morrDashboard.env.API_URL}/users/login`, payload, {
      headers: { "X-GOOGLE-TOKEN": googleRecaptchaToken },
    })
    .then((res) => {
      console.log("SENDING OTP", res);
      let recipient = isEmail ? username : emailMobileHandlerInstance.getNumber();
      showSuccessMessage(`Successfully Sent Otp to ${recipient}`);
      isOTPScreen = true;
      isLoginScreen = false;
      initializeCountDown();
    })
    .catch((err) => {
      console.log("request failed", err.response);
      setAuthCreds(false, "", "");
      parseAndShowErrorMessage(err);
      isOTPScreen = false;
      isLoginScreen = true;
    });
}

function requestLoginWithOTP(googleRecaptchaToken) {
  let payload = {
    code,
    mobile: username,
    otp: otp,
  };

  axios
    .post(`${morrDashboard.env.API_URL}/users/verify`, payload, {
      headers: {
        "X-GOOGLE-TOKEN": googleRecaptchaToken,
        "x-platform": "dashboard",
      },
    })
    .then((res) => {
      console.log("LOGGING_IN_WITH_OTP", res);

      setAuthCreds(true, res.data.access_token || "", res.data.refresh_token || "");

      showSuccessMessage("Successfully logged in");

      navigateTo("/merchants/dashboard");
    })
    .catch((err) => {
      console.log("request failed", err.response);
      setAuthCreds(false, "", "");
      parseAndShowErrorMessage(err);
    });
}

function resetForm() {
  isOTPScreen = false;
  isLoginScreen = true;

  // re-render intl-tel-input plugin because it is not re-rendered when switching between screens
  setTimeout(function () {
    emailMobileHandlerInstance.destroy();
    emailMobileHandlerInstance = null;
    emailMobileHandlerElement.style.paddingLeft = "0px";
    emailMobileHandlerHandler();
  }, 10);
}

function performLogin(mode = "password", action = "request_otp") {
  loginForm.validate();
  if ($loginForm.fields.username?.errors?.includes("required")) {
    showErrorMessage("Please enter email/ mobile number");
    return;
  } else if (mode === "password" && !password) {
    showErrorMessage("Please enter password");
    return;
  }

  grecaptcha.ready(function () {
    grecaptcha
      .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
        action: "Login",
      })
      .then(function (token) {
        if (token) {
          if (mode === "password") {
            requestLoginApi(token);
          } else if (mode === "otp" && action === "verify") {
            requestLoginWithOTP(token);
          } else if (mode === "otp" && action === "request_otp") {
            requestLoginOTP(token);
          }
        }
      });
  });
}

function enableFormValidationError(fieldName) {
  showFormValidationError[fieldName] = true;
  console.log(showFormValidationError);
}

function toggleCheckbox() {
  let temp = !rememberMe;
  rememberMe = temp;
}

function checkEmailTypeValidity() {
  isEmail = !/^[- +()]*[0-9][- +()0-9]*$/.test(username) || username.indexOf("@") !== -1;
}

function emailMobileHandlerHandler() {
  checkEmailTypeValidity();
  if (!isEmail && username) {
    if (!emailMobileHandlerInstance) {
      emailMobileHandlerInstance = initPhoneValidator(emailMobileHandlerElement);
      code = !code ? emailMobileHandlerInstance.getSelectedCountryData().dialCode || "+60" : code;
      if (username && code) {
        emailMobileHandlerInstance.setNumber(`+${code}${username}`);
      }
      emailMobileHandlerElement.addEventListener("countrychange", function () {
        let selectedCountryCode = emailMobileHandlerInstance.getSelectedCountryData().dialCode;
        code = selectedCountryCode;
      });
    } else {
      emailMobileHandlerInstance.setNumber(`${emailMobileHandlerInstance.getNumber()}`);
    }
    isValidMobileNumber = emailMobileHandlerInstance.isValidNumber();
  } else if (emailMobileHandlerInstance) {
    emailMobileHandlerInstance.destroy();
    emailMobileHandlerInstance = null;
    emailMobileHandlerElement.style.paddingLeft = "0px";
    isValidMobileNumber = true;
  }
  emailMobileHandlerElement.focus();
}
</script>

<div class="main-container">
  <div class="w-11/12 sm:10/12 md:w-9/12 lg:w-7/12 xl:w-6/12">
    <form class="forms-container form w-full p-8 leading-10 rounded-xl filter drop-shadow-md">
      <img class="w-16 my-6" src="https://morrhq.s3.ap-south-1.amazonaws.com/morr-320x.png" alt="Welcome to Morr" title="Welcome to Morr" />

      {#if isLoginScreen}
        <h1 class="text-center font-bold text-xl">Login to continue</h1>
        <input type="hidden" id="g-recaptcha-response" name="g-recaptcha-response" />
        <div class="w-10/12 hero bg-gredient-dark h-20 flex flex-col px-0 mt-4">
          <div class="w-full relative z-0">
            <input id="{`email-mobile-input`}" type="text" class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200" placeholder=" " bind:value="{username}" on:input="{emailMobileHandlerHandler}" bind:this="{emailMobileHandlerElement}" />

            <label for="email-mobile-input" class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500 text-sm"> Enter Email / Mobile Number </label>
            {#if username.length > 0}
              {#if !isValidMobileNumber}
                <div class="absolute right-0 duration-300 top-6 -z-1 origin-0 text-gray-500 text-sm text-red-500">
                  <span><i class="fas fa-times-circle"></i></span>
                </div>
              {:else if isValidMobileNumber && username.indexOf("@") !== -1}
                <div class="absolute right-0 duration-300 top-6 -z-1 origin-0 text-gray-500 text-sm text-green-500">
                  <span><i class="fas fa-check-circle"></i></span>
                </div>
              {/if}
            {/if}
          </div>
        </div>
        {#if username}
          <div class="w-10/12 hero bg-gredient-dark h-20 flex flex-col px-0 mt-2">
            <div class="w-full relative z-0">
              <input
                class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                id="password"
                type="password"
                placeholder=" "
                bind:value="{password}"
                on:change="{() => {
                  enableFormValidationError('password');
                }}"
                use:bindClass="{{
                  form: loginForm,
                  invalid: 'border-red-500 focus:outline-none focus:border-red-500',
                }}" />
              <label for="password" class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500 text-sm">
                <span>Password</span>
              </label>

              {#if showFormValidationError?.password}
                <div class="messages w-10/12">
                  {#if $loginForm && $loginForm.fields.password?.errors?.includes("required")}
                    <p class="flex font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">* The password is required</p>
                  {/if}
                </div>
              {/if}
            </div>
          </div>
        {/if}

        <div class="text-center w-full my-3">
          <button class="btn w-10/12 morr-background rounded-3xl uppercase py-0 h-10 font-bold outline-none text-white" type="button" on:click="{() => performLogin('password')}" disabled="{!$loginForm && $loginForm?.valid}">Login {!isEmail ? "With Password" : ""}</button>
        </div>
        {#if username && !isEmail}
          <div class="flex w-full text-center mx-auto justify-center align-middle m-auto">
            <div class="shadow-inner bg-gray-100 rounded-full w-10 h-10 text-sm font-bold text-black flex items-center justify-center">
              <span> OR </span>
            </div>
          </div>

          <div class="text-center w-full my-3">
            <button
              class="btn w-10/12 bg-gray-200 rounded-3xl uppercase py-0 h-10 font-bold outline-none text-gray-400"
              type="button"
              on:click="{() => {
                performLogin('otp');
              }}">
              Request OTP
            </button>
          </div>
        {/if}
      {:else if isOTPScreen}
        <h6 class="text-center text-sm">
          Please enter the OTP sent to {emailMobileHandlerInstance.getNumber()}
          <span class="text-blue-300" on:click="{() => resetForm()}"> Change number</span>
        </h6>
        <div class="w-10/12 hero bg-gredient-dark h-20 flex flex-col px-0 mt-4">
          <div class="w-full relative z-0">
            <input
              class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
              id="otp"
              type="text"
              placeholder=" "
              bind:value="{otp}"
              on:change="{() => {
                enableFormValidationError('otp');
              }}"
              use:bindClass="{{
                form: otpForm,
                invalid: 'border-red-500 focus:outline-none focus:border-red-500',
              }}" />
            <label for="otp" class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500 text-sm"> Enter OTP </label>

            {#if showFormValidationError?.otp}
              <div class="messages w-10/12">
                {#if $otpForm && $otpForm.fields.otp?.errors?.includes("required")}
                  <p class="flex font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">* The OTP is required</p>
                {/if}
              </div>
            {/if}
          </div>
        </div>
        <div class="text-center w-full my-3">
          <button class="w-5/12 morr-background rounded-3xl uppercase py-0 h-10 font-bold outline-none" type="button" on:click="{() => performLogin('otp', 'verify')}" disabled="{!otpForm && otpForm?.valid}">Login With Otp</button>
          <button class="{`w-5/12 morr-background-light border-2 rounded-3xl border-opacity-10 border-yellow-600 py-0 h-10 font-bold outline-none ${otpResendCountDown !== 0 ? 'opacity-40' : 'opacity-100'}`}" type="button" on:click="{() => performLogin('otp', 'request_otp')}" disabled="{otpResendCountDown > 0}">
            {#if otpResendCountDown !== 0}
              {`Resend in ${otpResendCountDown}`}
            {:else}
              {`Resend OTP`}
            {/if}
          </button>
        </div>
      {/if}

      <div class="flex relative mt-4 w-full z-2 justify-between px-16 text-lg font-normal text-black">
        <div class="space-x-2 inline-block">
          <Navigate to="/auth/forgot-password">
            <small>forgot password?</small>
          </Navigate>
        </div>

        <div class="space-x-2 inline-block">
          <Navigate to="/auth/register">
            <small>Create a new account</small>
          </Navigate>
        </div>
      </div>
    </form>
  </div>
</div>

<style windi:inject global windi:global windi:preflights:global windi:safelist:global>
:global(*), :global(::before), :global(::after) {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}
:global(*) {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
}
:global(:root) {
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
}
:global(:-moz-focusring) {
  outline: 1px dotted ButtonText;
}
:global(:-moz-ui-invalid) {
  box-shadow: none;
}
:global(::moz-focus-inner) {
  border-style: none;
  padding: 0;
}
:global(::-webkit-inner-spin-button), :global(::-webkit-outer-spin-button) {
  height: auto;
}
:global(::-webkit-search-decoration) {
  -webkit-appearance: none;
}
:global(::-webkit-file-upload-button) {
  -webkit-appearance: button;
  font: inherit;
}
:global([type='search']) {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
:global(abbr[title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
:global(body) {
  margin: 0;
  font-family: inherit;
  line-height: inherit;
}
:global(html) {
  -webkit-text-size-adjust: 100%;
  font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  line-height: 1.5;
}
:global(a) {
  color: inherit;
  text-decoration: inherit;
}
:global(b), :global(strong) {
  font-weight: bolder;
}
:global(button), :global(input), :global(optgroup), :global(select), :global(textarea) {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  padding: 0;
  line-height: inherit;
  color: inherit;
}
:global(button), :global(select) {
  text-transform: none;
}
:global(button), :global([type='button']), :global([type='reset']), :global([type='submit']) {
  -webkit-appearance: button;
}
:global(blockquote), :global(dl), :global(dd), :global(h1), :global(h2), :global(h3), :global(h4), :global(h5), :global(h6), :global(hr), :global(figure), :global(p), :global(pre) {
  margin: 0;
}
:global(button) {
  background-color: transparent;
  background-image: none;
}
:global(button:focus) {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
:global(button), :global([role="button"]) {
  cursor: pointer;
}
:global(code), :global(kbd), :global(samp), :global(pre) {
  font-size: 1em;
}
:global(fieldset) {
  margin: 0;
  padding: 0;
}
:global(hr) {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}
:global(h1), :global(h2), :global(h3), :global(h4), :global(h5), :global(h6) {
  font-size: inherit;
  font-weight: inherit;
}
:global(img) {
  border-style: solid;
}
:global(input::placeholder) {
  opacity: 1;
  color: #9ca3af;
}
:global(input::webkit-input-placeholder) {
  opacity: 1;
  color: #9ca3af;
}
:global(input::-moz-placeholder) {
  opacity: 1;
  color: #9ca3af;
}
:global(input:-ms-input-placeholder) {
  opacity: 1;
  color: #9ca3af;
}
:global(input::-ms-input-placeholder) {
  opacity: 1;
  color: #9ca3af;
}
:global(img), :global(svg), :global(video), :global(canvas), :global(audio), :global(iframe), :global(embed), :global(object) {
  display: block;
  vertical-align: middle;
}
:global(img), :global(video) {
  max-width: 100%;
  height: auto;
}
:global(legend) {
  padding: 0;
}
:global(ol), :global(ul) {
  list-style: none;
  margin: 0;
  padding: 0;
}
:global(progress) {
  vertical-align: baseline;
}
:global(pre), :global(code), :global(kbd), :global(samp) {
  font-family: ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
}
:global(small) {
  font-size: 80%;
}
:global(sub), :global(sup) {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
:global(sub) {
  bottom: -0.25em;
}
:global(sup) {
  top: -0.5em;
}
:global(summary) {
  display: list-item;
}
:global(table) {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}
:global(textarea) {
  resize: vertical;
}
:global(textarea::placeholder) {
  opacity: 1;
  color: #9ca3af;
}
:global(textarea::webkit-input-placeholder) {
  opacity: 1;
  color: #9ca3af;
}
:global(textarea::-moz-placeholder) {
  opacity: 1;
  color: #9ca3af;
}
:global(textarea:-ms-input-placeholder) {
  opacity: 1;
  color: #9ca3af;
}
:global(textarea::-ms-input-placeholder) {
  opacity: 1;
  color: #9ca3af;
}
:global(.messages) {
  display: flex;
  justify-content: flex-start;
}
:global(.main-container) {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
:global(.check) {
  display: flex;
  justify-content: flex-start;
}
:global(.form) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
:global(.forms-container) {
  background-color: #f5f1ea;
  border-radius: 10px;
  overflow: hidden;
  z-index: 2;
}
:global(.form input) {
  outline: none;
  border: none;
  border-bottom: 1px solid #b3b1b140;
  background: transparent;
  background-clip: content-box;
}
:global(.form-group) {
  display: block;
}
:global(.form-group input:checked + label:after) {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #e8ad90e0;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
:global(.iti--separate-dial-code .iti__selected-flag) {
  background-color: transparent !important;
}
:global(.space-x-2 > :not([hidden]) ~ :not([hidden])) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
:global(.appearance-none) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
:global(.bg-transparent) {
  background-color: transparent;
}
:global(.bg-gray-100) {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
:global(.bg-gray-200) {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
:global(.focus\:border-black:focus) {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}
:global(.border-gray-200) {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
:global(.border-yellow-600) {
  --tw-border-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity));
}
:global(.border-opacity-10) {
  --tw-border-opacity: 0.1;
}
:global(.rounded-xl) {
  border-radius: 0.75rem;
}
:global(.rounded-3xl) {
  border-radius: 1.5rem;
}
:global(.rounded-full) {
  border-radius: 9999px;
}
:global(.border-0) {
  border-width: 0px;
}
:global(.border-2) {
  border-width: 2px;
}
:global(.border-b-2) {
  border-bottom-width: 2px;
}
:global(.block) {
  display: block;
}
:global(.inline-block) {
  display: inline-block;
}
:global(.flex) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
:global(.flex-col) {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
:global(.items-center) {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
:global(.justify-center) {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
:global(.justify-between) {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
:global(.font-bold) {
  font-weight: 700;
}
:global(.font-medium) {
  font-weight: 500;
}
:global(.font-normal) {
  font-weight: 400;
}
:global(.h-20) {
  height: 5rem;
}
:global(.h-10) {
  height: 2.5rem;
}
:global(.text-xl) {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
:global(.text-sm) {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
:global(.text-xs) {
  font-size: 0.75rem;
  line-height: 1rem;
}
:global(.text-lg) {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
:global(.leading-10) {
  line-height: 2.5rem;
}
:global(.m-auto) {
  margin: auto;
}
:global(.my-6) {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
:global(.my-3) {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
:global(.mx-auto) {
  margin-left: auto;
  margin-right: auto;
}
:global(.mt-4) {
  margin-top: 1rem;
}
:global(.mt-0) {
  margin-top: 0px;
}
:global(.mt-2) {
  margin-top: 0.5rem;
}
:global(.mt-1) {
  margin-top: 0.25rem;
}
:global(.ml-1) {
  margin-left: 0.25rem;
}
:global(.opacity-40) {
  opacity: 0.4;
}
:global(.opacity-100) {
  opacity: 1;
}
:global(.focus\:outline-none:focus) {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
:global(.outline-none) {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
:global(.p-8) {
  padding: 2rem;
}
:global(.px-0) {
  padding-left: 0px;
  padding-right: 0px;
}
:global(.py-0) {
  padding-top: 0px;
  padding-bottom: 0px;
}
:global(.px-16) {
  padding-left: 4rem;
  padding-right: 4rem;
}
:global(.pt-3) {
  padding-top: 0.75rem;
}
:global(.pb-2) {
  padding-bottom: 0.5rem;
}
:global(.absolute) {
  position: absolute;
}
:global(.relative) {
  position: relative;
}
:global(.top-3) {
  top: 0.75rem;
}
:global(.right-0) {
  right: 0px;
}
:global(.top-6) {
  top: 1.5rem;
}
:global(.shadow-inner) {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: inset 0 2px 4px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
:global(.focus\:ring-0:focus) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
:global(.text-center) {
  text-align: center;
}
:global(.text-gray-500) {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
:global(.text-red-500) {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
:global(.text-green-500) {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}
:global(.text-white) {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
:global(.text-black) {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}
:global(.text-gray-400) {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
:global(.text-blue-300) {
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity));
}
:global(.uppercase) {
  text-transform: uppercase;
}
:global(.tracking-wide) {
  letter-spacing: 0.025em;
}
:global(.align-middle) {
  vertical-align: middle;
}
:global(.w-11\/12) {
  width: 91.666667%;
}
:global(.w-full) {
  width: 100%;
}
:global(.w-16) {
  width: 4rem;
}
:global(.w-10\/12) {
  width: 83.333333%;
}
:global(.w-10) {
  width: 2.5rem;
}
:global(.w-5\/12) {
  width: 41.666667%;
}
:global(.z-0) {
  z-index: 0;
}
:global(.-z-1) {
  z-index: -1;
}
:global(.z-2) {
  z-index: 2;
}
:global(.duration-300) {
  -webkit-transition-duration: 300ms;
  -o-transition-duration: 300ms;
  transition-duration: 300ms;
}
:global(.filter) {
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
:global(.drop-shadow-md) {
  --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
}
@media (min-width: 768px) {
  :global(.md\:w-9\/12) {
    width: 75%;
  }
}
@media (min-width: 1024px) {
  :global(.lg\:w-7\/12) {
    width: 58.333333%;
  }
}
@media (min-width: 1280px) {
  :global(.xl\:w-6\/12) {
    width: 50%;
  }
}
</style>
