<script>
import { onMount } from "svelte";
import { Navigate, navigateTo } from "svelte-router-spa";
import { form, bindClass } from "svelte-forms";
import axios from "axios";
import { showSuccessMessage } from "../../utils/toast";
import { parseAndShowErrorMessage } from "../../utils/errorParser";
import initPhoneValidator from "../../utils/phone-number";
import setAuthCreds from "../../utils/auth";
import { Circle } from "svelte-loading-spinners";

let code = null;
let mobile = "";
let otp = null;
let counter = 120;
let interval = null;
let isOTPSent = false;
let isOTPRequested = false;

let contactHandlerElement;
let contactHandlerInstance;
let isMounted = false;

$: if (mobile && isMounted) {
  let selectedCountryCode = code ? code : contactHandlerInstance.getSelectedCountryData().dialCode;
  contactHandlerInstance.setNumber(`+${selectedCountryCode}${mobile}`);
  code = selectedCountryCode;
}

onMount(() => {
  contactHandlerInstance = initPhoneValidator(contactHandlerElement);
  if (mobile && code) {
    contactHandlerInstance.setNumber(`+${code}${mobile}`);
  }
  isMounted = true;
  contactHandlerElement.addEventListener("countrychange", function () {
    let selectedCountryCode = contactHandlerInstance.getSelectedCountryData().dialCode;
    code = selectedCountryCode;
  });
});

function isNumeric(str) {
  if (typeof str != "string")
    return {
      name: "isNumeric",
      valid: false,
    };
  if (!isNaN(str) && !isNaN(parseFloat(str)) && str.length == 10) {
    return {
      name: "isNumeric",
      valid: true,
    };
  }
  return {
    name: "isNumeric",
    valid: false,
  };
}

const otpLoginForm = form(
  () => ({
    mobile: { value: mobile, validators: ["required", isNumeric] },
  }),
  {
    initCheck: false,
    validateOnChange: true,
  }
);

function requestOTP(googleToken) {
  isOTPRequested = true;
  let payload = {
    code,
    mobile,
  };

  axios
    .post(`${morrDashboard.env.API_URL}/users/login`, payload)
    .then((res) => {
      isOTPSent = true;

      // Begin the OTP countdown
      interval = setInterval(() => {
        if (counter <= 0) {
          clearInterval(interval);
        }
        counter--;
      }, 1000);

      console.log("OTP LOGIN LOG :: OTP SENT ON MAIL AND MOBILE", res);
      showSuccessMessage(res.data.message);
    })
    .catch((err) => {
      console.log("OTP LOGIN LOG :: OTP NOT SENT", err.response);
      parseAndShowErrorMessage(err);
    });
}

function requestLogin() {
  let payload = {
    code,
    mobile,
    otp,
  };

  axios
    .post(`${morrDashboard.env.API_URL}/users/verify`, payload, {
      headers: { "x-platform": "dashboard" },
    })
    .then((res) => {
      console.log("OTP LOGIN LOG :: LOGIN SUCCESSFUL", res);
      setAuthCreds(true, res.data.access_token || "", res.data.refresh_token || "");
      showSuccessMessage("Successfully logged in");

      navigateTo("/merchants/dashboard");
    })
    .catch((err) => {
      console.log("OTP LOGIN LOG :: LOGIN FAILED", err.response);
      parseAndShowErrorMessage(err);
    });
}

function sendOTP() {
  otpLoginForm.validate();

  if ($otpLoginForm && $otpLoginForm.fields.mobile?.errors?.includes("required")) {
    parseAndShowErrorMessage("Mobile number is required");
    return;
  }

  if ($otpLoginForm && $otpLoginForm.fields.mobile?.errors?.includes("isNumeric")) {
    parseAndShowErrorMessage("Enter valid mobile number");
    return;
  }

  grecaptcha.ready(function () {
    grecaptcha
      .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
        action: "OTPLogin",
      })
      .then(function (token) {
        if (token) {
          requestOTP(token);
        }
      });
  });
}

function submitOTP() {
  if (!mobile) {
    parseAndShowErrorMessage("Enter Mobile Number");
    return;
  }

  if (!code) {
    parseAndShowErrorMessage("Enter Country Code");
    return;
  }

  if (!otp) {
    parseAndShowErrorMessage("Enter OTP");
    return;
  }
  requestLogin();
}
</script>

<div class="main-container">
  <div class="w-11/12 sm:10/12 md:w-9/12 lg:w-7/12 xl:w-6/12">
    <form class="forms-container form w-full p-8 leading-10 rounded-xl filter drop-shadow-md">
      <img class="w-16 my-6" src="https://morrhq.s3.ap-south-1.amazonaws.com/morr-320x.png" alt="Welcome to Morr" title="Welcome to Morr" />
      <h1 class="text-center font-bold text-xl">OTP Login</h1>
      <input type="hidden" id="g-recaptcha-response" name="g-recaptcha-response" />
      <div class="w-10/12">
        <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="{`${mobile}-contact-number`}"> Mobile Number </label>

        <input
          id="{`${mobile}-contact-number`}"
          type="tel"
          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
          class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          placeholder="Please enter a valid mobile number"
          bind:value="{mobile}"
          on:input="{() => {
            isOTPSent = false;
            isOTPRequested = false;
            otp = null;
          }}"
          on:change="{() => {
            code = contactHandlerInstance.getSelectedCountryData().dialCode;
          }}"
          bind:this="{contactHandlerElement}" />
      </div>
      {#if $otpLoginForm && $otpLoginForm.fields.mobile?.errors?.includes("required")}
        <div class="messages w-10/12">
          <p class="flex font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">* The mobile is required</p>
        </div>
      {/if}
      {#if $otpLoginForm && $otpLoginForm.fields.mobile?.errors?.includes("isNumeric")}
        <div class="messages w-10/12">
          <p class="flex font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">* The mobile is invalid!</p>
        </div>
      {/if}
      {#if isOTPSent}
        <div class="w-10/12 my-6">
          <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="{'otp-number'}"> OTP </label>

          <input id="{'otp-number'}" type="number" pattern="[0-9]{6}" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Please enter an otp" bind:value="{otp}" />
        </div>
      {/if}

      {#if !isOTPSent}
        <div class="text-center w-full my-3">
          <button class="btn w-10/12 morr-background rounded-3xl uppercase py-0 h-10 font-bold outline-none" type="button" on:click="{() => sendOTP()}" disabled="{isOTPRequested}">
            {#if isOTPRequested}
              <div class="spinner-progress">
                <Circle size="18" color="#2d3748" unit="px" duration="1s" />
                &nbsp; Sending OTP
              </div>
            {:else if !isOTPRequested}
              Send OTP
            {/if}
          </button>
        </div>
      {:else}
        <div class="text-center w-full my-3">
          <button
            class="btn w-10/12 morr-background rounded-3xl uppercase py-0 h-10 font-bold outline-none"
            type="button"
            on:click="{() => {
              submitOTP();
            }}">
            Login
          </button>
        </div>
        <div class="text-center w-full my-3">
          <button
            class="btn w-10/12 morr-background rounded-3xl uppercase py-0 h-10 font-bold outline-none"
            type="button"
            on:click="{() => {
              counter = 120;
              sendOTP();
            }}"
            disabled="{counter > 0}">
            Resend OTP {counter <= 0 ? "" : "In " + counter + " seconds"}
          </button>
        </div>
      {/if}
      <div class="flex relative mt-4 w-full z-0 justify-between px-16 text-lg font-normal text-black">
        <div class="space-x-2 inline-block">
          <Navigate to="/auth/login">
            <small>Login with password?</small>
          </Navigate>
        </div>
        <div class="space-x-2 inline-block">
          <Navigate to="/auth/register">
            <small>Create new account</small>
          </Navigate>
        </div>
      </div>
    </form>
  </div>
</div>

<style windi:inject>
:global(.spinner-progress div) {
  display: inline-block;
  vertical-align: middle;
}
.messages {
  display: flex;
  justify-content: flex-start;
}
.main-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.check {
  display: flex;
  justify-content: flex-start;
}
.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.forms-container {
  background-color: #f5f1ea;
  border-radius: 10px;
  overflow: hidden;
  z-index: 2;
}
.form input {
  outline: none;
  border: none;
  border-bottom: 1px solid #b3b1b140;
  background: transparent;
}
.form-group {
  display: block;
}
.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.form-group label {
  position: relative;
  cursor: pointer;
}
.form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #e8ad90e0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}
.form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #e8ad90e0;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.rounded-xl {
  border-radius: 0.75rem;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-3xl {
  border-radius: 1.5rem;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.font-bold {
  font-weight: 700;
}
.font-medium {
  font-weight: 500;
}
.font-normal {
  font-weight: 400;
}
.h-10 {
  height: 2.5rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.leading-10 {
  line-height: 2.5rem;
}
.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mt-1 {
  margin-top: 0.25rem;
}
.ml-1 {
  margin-left: 0.25rem;
}
.mt-4 {
  margin-top: 1rem;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.p-8 {
  padding: 2rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}
.placeholder-gray-400::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.relative {
  position: relative;
}
.shadow {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 3px 0 rgba(var(--tw-shadow-color), 0.1), 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-center {
  text-align: center;
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.tracking-wide {
  letter-spacing: 0.025em;
}
.w-11\/12 {
  width: 91.666667%;
}
.w-full {
  width: 100%;
}
.w-16 {
  width: 4rem;
}
.w-10\/12 {
  width: 83.333333%;
}
.z-0 {
  z-index: 0;
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-linear {
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
[duration~="1s"] {
  -webkit-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
}
.duration-150 {
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.filter {
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
}
@media (min-width: 768px) {
  .md\:w-9\/12 {
    width: 75%;
  }
}
@media (min-width: 1024px) {
  .lg\:w-7\/12 {
    width: 58.333333%;
  }
}
@media (min-width: 1280px) {
  .xl\:w-6\/12 {
    width: 50%;
  }
}
</style>
