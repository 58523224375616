<script>
import { Datatable, rows } from "svelte-simple-datatables";
import axios from "axios";
import { authToken } from "../../store/auth";
import { isSuperAdmin } from "../../store/admin";
import { onMount } from "svelte";
import dateFormat from "dateformat";
import Pagination from "@fouita/pagination";

let current = 1;
let nextItemIndex = 0;
let num_items = 100;
let per_page = 10;

let color = "light";

let allTransactions = [];
let showTransactions = false;
let noTransactions = "loading transactions ...";

let transactionHistoryCache = {};
async function changePage(evt) {
  current = evt.detail;
  console.log("Navigating to page " + current);
  nextItemIndex = getNextSkipOffset(current, per_page);
  if (transactionHistoryCache.hasOwnProperty(current)) {
    allTransactions = transactionHistoryCache[current];
  }
  if (nextItemIndex > num_items) return;
  getTransactionApi(nextItemIndex, per_page);
}

function getNextSkipOffset(currentPage, perPage) {
  return (currentPage - 1) * perPage;
}

function getTransactionApi(skip, limit) {
  let headers = {
    "X-Auth-Token": $authToken,
  };
  axios
    .get(`${morrDashboard.env.API_URL}/admin/payments/transactions/history?skip=${skip}&limit=${limit}`, {
      headers: headers,
    })
    .then((res) => {
      console.log("ALL_Transactions_LISTING", res);
      allTransactions = res.data.data;
      transactionHistoryCache[current] = allTransactions;
      allTransactions = [...allTransactions];
      num_items = res.data.paging.total;
      nextItemIndex = res.data.paging.next;
      showTransactions = true;
    })
    .catch((err) => {
      console.error("ALL_Transactions_LISTING_ERROR", err);
      showTransactions = false;
      noTransactions = "Transactions not found";
    });
}

onMount(() => {
  nextItemIndex = 0;
  getTransactionApi(nextItemIndex, per_page);
});

const settings = {
  sortable: true,
  pagination: false,
  rowPerPage: 10,
  scrollY: false,
  columnFilter: true,
  noRows: "No entries to found",
  css: true,
  blocks: {
    searchInput: false,
    paginationButtons: true,
    paginationRowCount: true,
  },
};

function formatPaymentMode(paymentMode) {
  switch (paymentMode) {
    case "DD":
      return "NetBanking";
    case "WA":
      return "E-wallet";
    case "CC":
      return "Card";
    case "CASH":
      return "Pay Direct (Cash)";
    default:
      return paymentMode;
  }
}

function formatTransactionStatus(status) {
  switch (status) {
    case "0":
      return "Successful";
    case "1":
      return "Failed";
    case "2":
      return "Pending or expired";
    case "4":
      return "Rejected by Merchant";
    default:
      return "incomplete";
  }
}

function formatDateTime(dateTimeStr) {
  return dateFormat(dateTimeStr, "dddd, mmmm dS, yyyy, h:MM TT");
}

let lastRowColor = null;
let lastOrderId = null;
function getRowColor(numberOfRows, order_id, index) {
  let currentColor = "bg-gray-200";
  if (lastRowColor === currentColor) {
    currentColor = "bg-gray-100";
  }

  if (lastOrderId === order_id) {
    currentColor = lastRowColor;
  } else {
    lastRowColor = currentColor;
  }

  if (numberOfRows == 1) {
    currentColor = "";
  }

  lastOrderId = order_id;
  if (index === 0) {
    currentColor += " border-t-2 border-yellow-200";
  }
  return currentColor;
}

function changePageSize(event) {
  transactionHistoryCache = {};
  current = 1;
  nextItemIndex = 0;
  per_page = event.target.value;
  getTransactionApi(nextItemIndex, per_page);
}
</script>

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-gray-800 text-xl font-bold">Transaction History</h6>
    </div>
  </div>
  <div class="block w-full overflow-x-auto py-8 mb-4">
    {#if showTransactions}
      <Datatable settings="{settings}" data="{allTransactions}" class="items-center w-full bg-transparent border-collapse px-4">
        <thead>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="(row) => row.merchant.name"> Merchant Name </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="address.city"> Transaction ID </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="address.country"> Payment Mode </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="(row) => +row.contact_numbers[0].country_code - row.contact_numbers[0].phone_number"> Amount </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="ip_address"> Status </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="ip_address"> Time </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="ip_address"> Points Redeemed </th>
          <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left {color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-red-700 text-red-200 border-red-600'}" data-key="ip_address"> Points Credited </th>
        </thead>
        <tbody class="h-screen">
          {#each $rows as row}
            {#each row.transactions as transaction, index}
              <tr class="{transaction.TxnStatus === '0' ? 'bg-gradient-to-r from-green-300 to-blue-100' : ''}  {transaction.TxnStatus === '1' ? 'bg-gradient-to-l from-gray-300 to-red-100' : ''}  {getRowColor(row.transactions.length, row.order_id, index)} text-center ">
                {#if index == 0}
                  <th class="border-t-0 px-6 border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-left ">
                    <div class="flex items-center align-middle w-full">
                      <img src="{row.merchant.images.profile?.length > 0 ? row.merchant.images.profile[0] : '../assets/img/res-default.ico'}" class="h-12 w-12 bg-white rounded-full border inline" alt="{row.merchant.name}" />
                      <span class="ml-3 font-bold {color === 'light' ? 'btext-gray-700' : 'text-white'}">
                        {row.merchant.name}
                      </span>
                    </div>

                    <p class="block mt-4">
                      Order ID: #{row.order_id}
                    </p>
                  </th>
                {:else}
                  <th></th>
                {/if}

                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                  <p>{transaction.PaymentID}</p>
                </td>
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                  {formatPaymentMode(transaction.PymtMethod)}
                </td>
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                  {transaction.CurrencyCode}
                  <span class="font-bold">{transaction.Amount}</span>
                </td>
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 uppercase font-semibold">
                  {formatTransactionStatus(transaction.TxnStatus)}
                </td>
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                  {formatDateTime(transaction.created_at)}
                </td>
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                  {transaction.TxnStatus === "0" && transaction.Param7 ? transaction.Param7 : "-"}
                </td>
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                  {transaction.TxnStatus === "0" && transaction.Param6 ? transaction.Param6 : "-"}
                </td>
              </tr>
            {/each}
          {/each}
        </tbody>
      </Datatable>
      <div class="flex justify-between items-center px-4">
        <div class="flex items-center pagination">
          Per page:
          <select class="border px-2 rounded ml-2" bind:value="{per_page}" on:change="{changePageSize}">
            <option selected="{per_page == 10}">10</option>
            <option selected="{per_page == 25}">25</option>
            <option selected="{per_page == 50}">50</option>
          </select>
        </div>
        <Pagination bind:current num_items="{num_items}" per_page="{per_page}" on:navigate="{changePage}" />
      </div>
    {:else}
      <div class="block w-full overflow-x-auto m-4">
        <div>{noTransactions}</div>
      </div>
    {/if}
  </div>
</div>

<style windi:inject>
.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-transparent {
  background-color: transparent;
}
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}
.bg-gradient-to-r {
  background-image: -o-linear-gradient(left, var(--tw-gradient-stops));
  background-image: -webkit-gradient(linear, left top, right top, from(var(--tw-gradient-stops)));
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}
.bg-gradient-to-l {
  background-image: -o-linear-gradient(right, var(--tw-gradient-stops));
  background-image: -webkit-gradient(linear, right top, left top, from(var(--tw-gradient-stops)));
  background-image: linear-gradient(to left, var(--tw-gradient-stops));
}
.from-green-300 {
  --tw-gradient-from: rgba(110, 231, 183, var(--tw-from-opacity, 1));
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}
.from-gray-300 {
  --tw-gradient-from: rgba(209, 213, 219, var(--tw-from-opacity, 1));
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}
.to-blue-100 {
  --tw-gradient-to: rgba(219, 234, 254, var(--tw-to-opacity, 1));
}
.to-red-100 {
  --tw-gradient-to: rgba(254, 226, 226, var(--tw-to-opacity, 1));
}
.border-collapse {
  border-collapse: collapse;
}
.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity));
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.border-solid {
  border-style: solid;
}
.border-0 {
  border-width: 0px;
}
.border {
  border-width: 1px;
}
.border-l-0 {
  border-left-width: 0px;
}
.border-r-0 {
  border-right-width: 0px;
}
.block {
  display: block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.font-bold {
  font-weight: 700;
}
.font-semibold {
  font-weight: 600;
}
.h-screen {
  height: 100vh;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-0 {
  margin-bottom: 0px;
}
.mb-4 {
  margin-bottom: 1rem;
}
.min-w-0 {
  min-width: 0px;
}
.overflow-x-auto {
  overflow-x: auto;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.relative {
  position: relative;
}
.shadow-lg {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.text-red-200 {
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.align-middle {
  vertical-align: middle;
}
.break-words {
  overflow-wrap: break-word;
}
.w-full {
  width: 100%;
}
</style>