<script>
import { form, bindClass } from "svelte-forms";
import axios from "axios";
import { onMount } from "svelte";

import FilePond, { registerPlugin } from "svelte-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { authToken } from "../../store/auth";
import { navigateTo } from "svelte-router-spa";
import { showSuccessMessage } from "../../utils/toast";
import { parseAndShowErrorMessage } from "../../utils/errorParser";
import { isSuperAdmin } from "../../store/admin";
import Tags from "svelte-tags-input";
import Select from "svelte-select";
import { defaultMerchantLabels, merchantCategories } from "../../constants/merchants";

export let data;
export let merchant_id;

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

let baseApiURl = morrDashboard.env.API_URL;

let filepond_input_name = "filepond";
let banners_pond;
let cover_pond;
let profile_pond;
let portrait_pond;

let existing_covers = [];
let existing_banners = [];
let existing_profile = [];
let existing_portrait = [];

let validationError;
let successMessage;

existing_covers = data.images?.cover?.map((link) => {
  return {
    source: link,
    options: {
      type: "local",
    },
  };
});
existing_banners = data.images?.banners?.map((link) => {
  return {
    source: link,
    options: {
      type: "local",
    },
  };
});
existing_profile = data.images?.profile?.map((link) => {
  return {
    source: link,
    options: {
      type: "local",
    },
  };
});
existing_portrait = data.images?.portrait?.map((link) => {
  return {
    source: link,
    options: {
      type: "local",
    },
  };
});

let selectedMerchantCategory;

let numberOfSchedules = 0;

let merchantFormFields = {
  merchant: {
    title: "",
    websiteUrl: "",
    websiteButtonText: "",
    rating: "5",
    schedules: [
      {
        start: "00:00",
        end: "00:00",
      },
    ],
    deliveryRange: 0,
    discounts: {
      bronze: {
        thresold: "",
        increment_by: "amount",
        type: "percentage",
        cashback: "3",
      },
      silver: {
        thresold: "",
        increment_by: "amount",
        type: "percentage",
        cashback: "5",
      },
      gold: {
        thresold: "",
        increment_by: "amount",
        type: "percentage",
        cashback: "8",
      },
    },
    images: {
      profile: [],
      cover: [],
      banners: [],
      portrait: [],
      landscape: [],
    },
    category: "",
    labels: [],
    googleReviewsUrl: "",
  },
};

function updateMerchantDetails() {
  merchantFormFields.merchant.title = data.name ? data.name : "";
  merchantFormFields.merchant.websiteUrl = data.website_url ? data.website_url : "";
  merchantFormFields.merchant.websiteButtonText = data.website_button_text ? data.website_button_text : "";
  merchantFormFields.merchant.deliveryRange = data.delivery_range ? data.delivery_range : 0;
  merchantFormFields.merchant.rating = data.rating || data.rating === 0 ? data.rating : merchantFormFields.merchant.rating;
  merchantFormFields.merchant.schedules = data.schedule ? data.schedule : [];
  merchantFormFields.merchant.discounts = Object.keys(data.point_tiers)?.length === 0 && data.point_tiers.constructor === Object ? merchantFormFields.merchant.discounts : data.point_tiers;
  merchantFormFields.merchant.images = Object.keys(data.images)?.length === 0 && data.images.constructor === Object ? merchantFormFields.merchant.images : data.images;

  merchantFormFields.merchant.category = data.category;
  merchantFormFields.merchant.labels = data.labels || [];
  merchantFormFields.merchant.googleReviewsUrl = data.google_reviews_url || "";

  numberOfSchedules = merchantFormFields.merchant.schedules?.length;
  getSelectedMerchantCategory();
}

const updateMerchantForm = form(
  () => ({
    merchant_title: {
      value: merchantFormFields.merchant.title,
      validators: ["required"],
    },
    merchant_website_url: {
      value: merchantFormFields.merchant.websiteUrl,
    },
    merchant_website_button_text: {
      value: merchantFormFields.merchant.websiteButtonText,
    },
    merchant_rating: {
      value: merchantFormFields.merchant.rating,
      validators: ["required"],
    },
  }),
  {
    initCheck: true,
    validateOnChange: true,
  }
);

function getUploadImageUrls() {
  return merchantFormFields.merchant.images;
}

function getDiscoutTiers() {
  return merchantFormFields.merchant.discounts;
}

function getSchedule() {
  return merchantFormFields.merchant.schedules;
}

function buildFormPayload(googleRecaptchaToken) {
  return {
    updated_merchant: {
      name: merchantFormFields.merchant.title,
      images: getUploadImageUrls(),
      rating: merchantFormFields.merchant.rating,
      website_url: merchantFormFields.merchant.websiteUrl,
      website_button_text: merchantFormFields.merchant.websiteButtonText,
      point_tiers: getDiscoutTiers(),
      schedule: getSchedule(),
      delivery_range: merchantFormFields.merchant.deliveryRange,
      category: merchantFormFields.merchant.category.value,
      labels: merchantFormFields.merchant.labels,
      google_reviews_url: merchantFormFields.merchant.googleReviewsUrl,
    },
    captcha: {
      captcha_token: googleRecaptchaToken,
    },
  };
}

function postToUpdateMerchantApi(payload) {
  axios
    .put(`${baseApiURl}/admin/merchants/update/${merchant_id}`, payload, {
      headers: {
        "X-Auth-Token": $authToken,
      },
    })
    .then((response) => {
      successMessage = "Successfully updated.";
      showSuccessMessage(successMessage);
      console.log("MERCHANT_UPDATE", response);
      navigateTo("/merchants/list");
    })
    .catch((err) => {
      parseAndShowErrorMessage(err);
      console.error("MERCHANT_UPDATE_ERROR", err);
    });
}

function updateMerchant() {
  console.log("Merchant Payload", merchantFormFields);
  grecaptcha.ready(function () {
    grecaptcha
      .execute(`${morrDashboard.env.GOOGLE_RECAPTCHA_SITE_KEY}`, {
        action: "MerchantUpdate",
      })
      .then(function (token) {
        if (token) {
          let payload = buildFormPayload(token);
          postToUpdateMerchantApi(payload);
        }
      });
  });
}

function addMoreSchedule() {
  merchantFormFields.merchant.schedules.push({ start: "00:00", end: "00:00" });
  numberOfSchedules = merchantFormFields.merchant.schedules?.length;
}

function removeSchedule(event, index) {
  event.preventDefault();
  merchantFormFields.merchant.schedules.splice(index, 1);
  numberOfSchedules = merchantFormFields.merchant.schedules?.length;
}

function getFilePondServerConfig(image_for, image_type) {
  return {
    url: `${baseApiURl}/admin/images`,
    process: {
      url: "/upload",
      method: "POST",
      withCredentials: false,
      headers: {
        "X-Auth-Token": $authToken,
      },
      timeout: 7000,
      ondata: (formdata) => {
        formdata.append("image_for", image_for);
        formdata.append("image_type", image_type);
        console.log(banners_pond.getFiles());
        return formdata;
      },
      onload: (response) => {
        response = JSON.parse(response);
        let image_id = response.data.image_id;
        let image_url = response.data.file_url;
        if (!merchantFormFields.merchant.images[image_type]) {
          merchantFormFields.merchant.images[image_type] = [];
        }
        merchantFormFields.merchant.images[image_type].push(image_url);
        return image_id;
      },
      onerror: (response) => {
        let err = JSON.parse(response);
        parseAndShowErrorMessage(err);
        return err.detail;
      },
    },
    revert: (uniqueFileId, load, error) => {
      axios
        .delete(`${baseApiURl}/admin/images/${uniqueFileId}`, {
          headers: {
            "X-Auth-Token": $authToken,
          },
        })
        .then((res) => {
          console.log("Images before", merchantFormFields.merchant.images[image_type]);
          let elementIndex = merchantFormFields.merchant.images[image_type].indexOf(uniqueFileId);
          merchantFormFields.merchant.images[image_type].splice(elementIndex, 1);
          console.log("Images after", merchantFormFields.merchant.images[image_type]);
          load();
        })
        .catch((err) => {
          parseAndShowErrorMessage(err);
          console.error("FAILED_TO_REMOVE_IMAGE", err);
          error(err);
        });
    },
  };
}

function removeUploadedFile(err, file, fileArray) {
  const indexOfFileToBeDeleted = fileArray.findIndex((source) => {
    return source === file.source;
  });
  fileArray.splice(indexOfFileToBeDeleted, 1);
}

function visitMerchantProfilePage() {
  navigateTo(`/merchants/profile/${merchant_id}`);
}

function handleMerchantCategorySelect(event) {
  merchantFormFields.merchant.category = event.detail || [];
}

function getSelectedMerchantCategory() {
  let filteredCategories = merchantCategories.filter((category) => {
    if (category.value === merchantFormFields.merchant.category) {
      return category;
    }
  });
  console.log("Updating selected category", filteredCategories);
  if (filteredCategories.length > 0) {
    selectedMerchantCategory = filteredCategories[0];
  }

  return;
}

onMount(() => {
  updateMerchantDetails();
});
</script>

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-gray-800 text-xl font-bold">Edit Merchant</h6>
      <button class="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button" on:click="{visitMerchantProfilePage}"> Profile </button>
    </div>
  </div>
  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <form>
      <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">Merchant Information</h6>

      <div class="flex flex-wrap">
        <div class="w-full px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="merchant-title"> Name </label>
            <input id="merchant-title" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Merchant Name" bind:value="{merchantFormFields.merchant.title}" use:bindClass="{{ form: updateMerchantForm, name: 'merchant_title' }}" />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="merchant-website-url"> Website Url </label>
            <input id="website-url" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Website Url" bind:value="{merchantFormFields.merchant.websiteUrl}" use:bindClass="{{ form: updateMerchantForm, website_url: 'merchant_website_url' }}" />
          </div>
        </div>

        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="merchant-website-button-text"> Website Button Text </label>
            <input id="website-button" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Button Text" bind:value="{merchantFormFields.merchant.websiteButtonText}" use:bindClass="{{ form: updateMerchantForm, website_btn_txt: 'merchant_website_button_text' }}" />
          </div>
        </div>

        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="merchant-tagline"> Rating </label>
            <input id="merchant-tagline" type="number" max="5.0" min="1" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Merchant's rating" bind:value="{merchantFormFields.merchant.rating}" use:bindClass="{{ form: updateMerchantForm, name: 'merchant_rating' }}" readonly="{!$isSuperAdmin}" disabled="{!$isSuperAdmin}" />
          </div>
        </div>

        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="delivery-range"> Delivery Range (KMs) </label>
            <input id="delivery-range" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Delivery Range" bind:value="{merchantFormFields.merchant.deliveryRange}" />
          </div>
        </div>

        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="merchant-category"> Category </label>
            <Select containerStyles="min-height: 4rem; color: rgba(113, 128, 150, 1); background-color: rgba(247, 250, 252, 1); padding: 0.5rem 1.5rem; vertical-align:middle" inputAttributes="{{ class: 'h-16 bg-gray-100 text-grey-darker text-grey-darkest border border-gray-100 font-bold w-full py-1 px-2 outline-none text-lg text-gray-600' }}" inputStyles="font-size: 1.125rem;" items="{merchantCategories}" bind:value="{selectedMerchantCategory}" isMulti="{false}" placeholder="Please select a category" on:select="{handleMerchantCategorySelect}" />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="merchant-labels"> Labels </label>
            <Tags
              tags="{merchantFormFields.merchant.labels}"
              on:tags="{(event) => {
                console.log('Adding tag', event);
                merchantFormFields.merchant.labels = event.detail.tags;
              }}"
              addKeys="{[9]}"
              maxTags="{10}"
              allowPaste="{true}"
              allowDrop="{true}"
              splitWith="{'/'}"
              onlyUnique="{true}"
              removeKeys="{[27]}"
              placeholder="{'Start typing for label suggestions'}"
              autoComplete="{defaultMerchantLabels}"
              name="{'labels'}"
              id="{'merchant-labels'}"
              allowBlur="{true}"
              disable="{false}"
              minChars="{1}"
              labelText=""
              labelShow="{false}" />
          </div>
        </div>
      </div>
      <div class="w-full px-4">
        <div class="relative w-full mb-3">
          <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="google-reviews-url"> Google Reviews </label>
          <input id="google-reviews-url" type="text" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Please enter google reviews url" bind:value="{merchantFormFields.merchant.googleReviewsUrl}" />
        </div>
      </div>

      <hr class="mt-6 border-b-1 border-gray-400" />

      <div class="rounded-t mb-0 px-6 py-6">
        <div class="text-center flex justify-between">
          <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">Schedules</h6>
          <button class="bg-gray-700 text-white active:bg-gray-800 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-8" type="button" on:click|preventDefault="{addMoreSchedule}"> Add Schedule </button>
        </div>
      </div>

      {#each Array(numberOfSchedules) as _, index}
        <div class="flex flex-wrap">
          <div class="flex-grow px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="{`${index}-schedule-start`}"> Start </label>
              <input id="{`${index}-schedule-start`}" type="time" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="Start time" bind:value="{merchantFormFields.merchant.schedules[index].start}" use:bindClass="{{ form: updateMerchantForm, name: 'merchant_schedule_start' }}" />
            </div>
          </div>
          <div class="flex-grow px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="{`${index}-schedule-end`}"> End </label>
              <input id="{`${index}-schedule-end`}" type="time" class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" placeholder="End of schedule" bind:value="{merchantFormFields.merchant.schedules[index].end}" use:bindClass="{{ form: updateMerchantForm, name: 'merchant_schedule_end' }}" />
            </div>
          </div>

          <div class="w-8">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="{`${index}-delete`}"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </label>
            <button class="flex-shrink align-text-bottom" on:click="{(event) => removeSchedule(event, index)}">
              <i class="fas fa-trash text-red-500"></i>
            </button>
          </div>
        </div>
      {/each}

      <hr class="mt-6 border-b-1 border-gray-400" />

      <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">Cashback</h6>

      <div class="flex flex-auto flex-wrap p-3 xs:w-full" style="{'background: #cd7f32;'}">
        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="{`bronze-discount`}"> Type </label>
            <select class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" bind:value="{merchantFormFields.merchant.discounts.bronze.type}" use:bindClass="{{ form: updateMerchantForm }}">
              <option value="percentage">Percentage</option>
              <option value="flat">Flat</option>
            </select>
          </div>
        </div>

        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="{`bronze-discount`}">
              <div class="tooltip right cashback">
                Cashback<i class="fas fa-info-circle ml-2"></i>
              </div></label>
            <input class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" type="number" min="1" bind:value="{merchantFormFields.merchant.discounts.bronze.cashback}" use:bindClass="{{ form: updateMerchantForm }}" />
          </div>
        </div>
        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="{`bronze-discount`}">
              <div class="tooltip right thresold">
                Minimum Spend for Bronze Level<i class="fas fa-info-circle ml-2"></i>
              </div></label>
            <input class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" type="number" min="1" bind:value="{merchantFormFields.merchant.discounts.bronze.thresold}" use:bindClass="{{ form: updateMerchantForm }}" />
          </div>
        </div>

        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="{`bronze-discount`}"> Level Change By </label>
            <select class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" bind:value="{merchantFormFields.merchant.discounts.bronze.increment_by}" use:bindClass="{{ form: updateMerchantForm }}">
              <option value="amount">Amount</option>
              <option value="visits">Visits</option>
              <option value="points">Points</option>
            </select>
          </div>
        </div>
      </div>

      <div class="m-8"></div>

      <div class="flex flex-wrap p-3" style="{'background: #C0C0C0;'}">
        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="{`silver-discount`}"> Type </label>
            <select class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" bind:value="{merchantFormFields.merchant.discounts.silver.type}" use:bindClass="{{ form: updateMerchantForm }}">
              <option value="percentage">Percentage</option>
              <option value="flat">Flat</option>
            </select>
          </div>
        </div>

        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="{`silver-discount`}">
              <div class="tooltip right cashback">
                Cashback<i class="fas fa-info-circle ml-2"></i>
              </div></label>
            <input class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" type="number" min="1" bind:value="{merchantFormFields.merchant.discounts.silver.cashback}" use:bindClass="{{ form: updateMerchantForm }}" />
          </div>
        </div>
        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="{`silver-discount`}">
              <div class="tooltip right thresold">
                Minimum Spend for Silver Level<i class="fas fa-info-circle ml-2"></i>
              </div></label>
            <input class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" type="number" min="1" bind:value="{merchantFormFields.merchant.discounts.silver.thresold}" use:bindClass="{{ form: updateMerchantForm }}" />
          </div>
        </div>

        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="{`silver-level`}"> Level Change By </label>
            <select class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" bind:value="{merchantFormFields.merchant.discounts.silver.increment_by}" use:bindClass="{{ form: updateMerchantForm }}">
              <option value="amount">Amount</option>
              <option value="visits">Visits</option>
              <option value="points">Points</option>
            </select>
          </div>
        </div>
      </div>

      <div class="m-8"></div>

      <div class="flex flex-wrap p-3" style="{'background: #FFD700;'}">
        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="{`gold-discount`}"> Type </label>
            <select class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" bind:value="{merchantFormFields.merchant.discounts.gold.type}" use:bindClass="{{ form: updateMerchantForm }}">
              <option value="percentage">Percentage</option>
              <option value="flat">Flat</option>
            </select>
          </div>
        </div>

        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="{`gold-discount`}">
              <div class="tooltip right cashback">
                Cashback<i class="fas fa-info-circle ml-2"></i>
              </div></label>
            <input class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" type="number" min="1" bind:value="{merchantFormFields.merchant.discounts.gold.cashback}" use:bindClass="{{ form: updateMerchantForm }}" />
          </div>
        </div>
        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="{`gold-discount`}">
              <div class="tooltip right thresold">
                Minimum Spend for Gold Level<i class="fas fa-info-circle ml-2"></i>
              </div></label>
            <input class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" type="number" min="1" bind:value="{merchantFormFields.merchant.discounts.gold.thresold}" use:bindClass="{{ form: updateMerchantForm }}" />
          </div>
        </div>

        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" for="{`gold-level`}"> Level Change By </label>
            <select class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150" bind:value="{merchantFormFields.merchant.discounts.gold.increment_by}" use:bindClass="{{ form: updateMerchantForm }}">
              <option value="amount">Amount</option>
              <option value="visits">Visits</option>
              <option value="points">Points</option>
            </select>
          </div>
        </div>
      </div>
      <hr class="mt-6 border-b-1 border-gray-400" />

      {#if existing_covers?.length > 0 || existing_profile?.length > 0 || existing_banners?.length > 0 || existing_portrait?.length > 0}
        <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">Uploaded Images</h6>
      {/if}

      {#if existing_covers?.length > 0}
        <div class="w-full image_full">
          <small class="font-bold">Cover Photos</small>
          <FilePond
            bind:this="{cover_pond}"
            filepond_input_name="{filepond_input_name}"
            labelIdle="{''}"
            server="{{
              load: async (source, load, error, progress, abort, headers) => {
                fetch(source, {
                  method: 'GET',
                  mode: 'cors',
                  cache: 'no-cache',
                  headers: {
                    Origin: window.location.origin,
                  },
                })
                  .then(function (response) {
                    response.blob().then(function (imageBlob) {
                      load(imageBlob);
                    });
                  })
                  .catch(function (_error) {
                    error(_error);
                  });
              },
            }}"
            allowMultiple="{true}"
            allowBrowse="{false}"
            allowPaste="{false}"
            allowDrop="{false}"
            allowProcess="{false}"
            files="{existing_covers}"
            onremovefile="{(err, file) => removeUploadedFile(err, file, merchantFormFields.merchant.images.cover)}" />
        </div>
      {/if}

      {#if existing_profile?.length > 0}
        <div class="w-full image_full">
          <small class="font-bold">Profile Photos</small>
          <FilePond
            bind:this="{profile_pond}"
            filepond_input_name="{filepond_input_name}"
            labelIdle="{''}"
            server="{{
              load: async (source, load, error, progress, abort, headers) => {
                fetch(source, {
                  method: 'GET',
                  mode: 'cors',
                  cache: 'no-cache',
                  headers: {
                    Origin: window.location.origin,
                  },
                })
                  .then(function (response) {
                    response.blob().then(function (imageBlob) {
                      load(imageBlob);
                    });
                  })
                  .catch(function (_error) {
                    error(_error);
                  });
              },
            }}"
            allowMultiple="{true}"
            allowBrowse="{false}"
            allowPaste="{false}"
            allowDrop="{false}"
            allowProcess="{false}"
            files="{existing_profile}"
            onremovefile="{(err, file) => removeUploadedFile(err, file, merchantFormFields.merchant.images.profile)}" />
        </div>
      {/if}

      {#if existing_banners?.length > 0}
        <div class="w-full image_multi">
          <small class="font-bold">Carousel Images</small>
          <FilePond
            bind:this="{banners_pond}"
            filepond_input_name="{filepond_input_name}"
            labelIdle="{''}"
            server="{{
              load: async (source, load, error, progress, abort, headers) => {
                fetch(source, {
                  method: 'GET',
                  mode: 'cors',
                  cache: 'no-cache',
                  headers: {
                    Origin: window.location.origin,
                  },
                })
                  .then(function (response) {
                    response.blob().then(function (imageBlob) {
                      load(imageBlob);
                    });
                  })
                  .catch(function (_error) {
                    error(_error);
                  });
              },
            }}"
            allowMultiple="{true}"
            allowBrowse="{false}"
            allowPaste="{false}"
            allowDrop="{false}"
            allowProcess="{false}"
            files="{existing_banners}"
            onremovefile="{(err, file) => removeUploadedFile(err, file, merchantFormFields.merchant.images.banners)}" />
        </div>
      {/if}

      {#if existing_portrait?.length > 0}
        <div class="w-full image_multi">
          <small class="font-bold">Recent Photos</small>
          <FilePond
            bind:this="{banners_pond}"
            filepond_input_name="{filepond_input_name}"
            labelIdle="{''}"
            server="{{
              load: async (source, load, error, progress, abort, headers) => {
                fetch(source, {
                  method: 'GET',
                  mode: 'cors',
                  cache: 'no-cache',
                  headers: {
                    Origin: window.location.origin,
                  },
                })
                  .then(function (response) {
                    response.blob().then(function (imageBlob) {
                      load(imageBlob);
                    });
                  })
                  .catch(function (_error) {
                    error(_error);
                  });
              },
            }}"
            allowMultiple="{true}"
            allowBrowse="{false}"
            allowPaste="{false}"
            allowDrop="{false}"
            allowProcess="{false}"
            files="{existing_portrait}"
            onremovefile="{(err, file) => removeUploadedFile(err, file, merchantFormFields.merchant.images.portrait)}" />
        </div>
      {/if}

      <h6 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">Add More Images</h6>

      <div class="w-full image_full">
        <small class="font-bold">Upload Cover Photos</small>
        <FilePond required="{true}" bind:this="{cover_pond}" filepond_input_name="{filepond_input_name}" server="{getFilePondServerConfig('merchants', 'cover')}" allowMultiple="{true}" />
      </div>
      <div class="w-full image_full">
        <small class="font-bold">Upload Profile Photos</small>
        <FilePond bind:this="{profile_pond}" filepond_input_name="{filepond_input_name}" server="{getFilePondServerConfig('merchants', 'profile')}" allowMultiple="{true}" />
      </div>

      <div class="flex flex-wrap items-center w-full image_multi">
        <small class="font-bold">Upload Carousel Images</small>
        <div class="flex-auto w-full">
          <FilePond bind:this="{banners_pond}" filepond_input_name="{filepond_input_name}" server="{getFilePondServerConfig('merchants', 'cover')}" allowMultiple="{true}" />
        </div>

        <div class="flex-auto w-full image_multi">
          <small class="font-bold">Upload Recent Photos</small>
          <FilePond bind:this="{portrait_pond}" filepond_input_name="{filepond_input_name}" server="{getFilePondServerConfig('merchants', 'portrait')}" allowMultiple="{true}" />
        </div>
      </div>

      <hr class="mt-6 border-b-1 border-gray-400" />
      <div class="flex flex-wrap items-center">
        <div class="w-6/12 px-4 mt-4 mx-auto">
          <button class="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none w-full  ease-linear transition-all duration-150" type="submit" disabled="{!$updateMerchantForm.valid}" on:click|preventDefault="{() => updateMerchant()}"> Update merchant </button>
        </div>
      </div>
    </form>
  </div>
</div>

<style windi:inject>
:global(input.invalid) {
  border: 1px solid rgb(241, 48, 48);
}
.tooltip {
  position: relative;
  color: black;
  margin: 0 auto;
}
.tooltip::before {
  position: absolute;
  z-index: 2;
  width: 200px;
  max-width: 200px;
  padding: 10px;
  display: none;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
}
.tooltip::after {
  position: absolute;
  z-index: 1;
  display: none;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  content: "";
}
.tooltip:hover::before, .tooltip:hover::after {
  display: inline-block;
}
.tooltip.right:before {
  top: 50%;
  left: calc(20% + 5px);
  transform: translateY(-50%);
}
.tooltip.right:after {
  top: 50%;
  left: 20%;
  transform: translateY(-50%);
  border-left-width: 0;
  border-right-color: rgba(0, 0, 0, 0.8);
}
.tooltip.right.cashback:before {
  content: "amount to be awarded to user per successful transaction.";
  top: 50%;
  left: calc(30% + 5px);
  transform: translateY(-50%);
}
.tooltip.right.cashback:after {
  top: 50%;
  left: 30%;
  transform: translateY(-50%);
  border-left-width: 0;
  border-right-color: rgba(0, 0, 0, 0.8);
}
.tooltip.right.thresold:before {
  content: "max amount of points at which user level will be upgraded to next tier.";
  top: 50%;
  left: calc(70% + 5px);
  transform: translateY(-50%);
}
.tooltip.right.thresold:after {
  top: 50%;
  left: 70%;
  transform: translateY(-50%);
  border-left-width: 0;
  border-right-color: rgba(0, 0, 0, 0.8);
}
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.active\:bg-red-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.active\:bg-gray-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}
.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}
.active\:bg-gray-700:active {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}
.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.border {
  border-width: 1px;
}
.border-0 {
  border-width: 0px;
}
.border-b-1 {
  border-bottom-width: 1px;
}
.block {
  display: block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.flex-auto {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.flex-grow {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
.flex-shrink {
  -ms-flex-negative: 1;
  -webkit-flex-shrink: 1;
  flex-shrink: 1;
}
.font-bold {
  font-weight: 700;
}
.h-16 {
  height: 4rem;
}
.h-8 {
  height: 2rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.m-8 {
  margin: 2rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-0 {
  margin-bottom: 0px;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.mt-4 {
  margin-top: 1rem;
}
.min-w-0 {
  min-width: 0px;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.p-3 {
  padding: 0.75rem;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.pt-0 {
  padding-top: 0px;
}
.placeholder-gray-400::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.relative {
  position: relative;
}
.shadow-lg {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 3px 0 rgba(var(--tw-shadow-color), 0.1), 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-md:hover {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 4px 6px -1px rgba(var(--tw-shadow-color), 0.1), 0 2px 4px -1px rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-lg:hover {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 10px 15px -3px rgba(var(--tw-shadow-color), 0.1), 0 4px 6px -2px rgba(var(--tw-shadow-color), 0.05);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-center {
  text-align: center;
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.align-text-bottom {
  vertical-align: text-bottom;
}
.break-words {
  overflow-wrap: break-word;
}
.w-full {
  width: 100%;
}
.w-8 {
  width: 2rem;
}
.w-6\/12 {
  width: 50%;
}
.transition-all {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.ease-linear {
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
.duration-150 {
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
[max~="5.0"] {
  max-5: 1.25rem;
}
[min~="1"] {
  min-1: 0.25rem;
}
@media (min-width: 1024px) {
  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .lg\:w-6\/12 {
    width: 50%;
  }
  .lg\:w-3\/12 {
    width: 25%;
  }
}
</style>
