<script>
export let productTemplateInfo;
</script>

<div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-16">
  <div class="px-6">
    <div class="flex flex-wrap justify-center">
      <div class="w-full px-4 flex justify-center ring-2 ring-offset-gray-50">
        <div class="relative">
          <img alt="{productTemplateInfo.name}" title="productTitle" src="https://app.morr.my/assets/icon/morr-white-transparent.png" class="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-21 lg: max-w-150-px" style="background-color: #e5a68c;" />
        </div>
      </div>
    </div>
    <div class="information m-8 p-28 pb-4 items-center text-center shadow-2xl">
      <div class="name-div m-4">
        <h1 class="name font-bold text-3xl uppercase underline">
          {productTemplateInfo.name}
        </h1>
      </div>
      <div class="price-div m-8">
        <h3 class="price font-semibold text-2xl text-red-400">
          RM <span style="font-variant-numeric: oldstyle-nums; font-size: 40px; color:gray;"> {productTemplateInfo.price}</span>
        </h3>
        <span class="text-md text-gray-500 underline">Price</span>
      </div>
      <div class="description-div">
        <span class="text-md text-gray-500 underline">About: </span>
        <h2 class="description font-normal text-lg font-mono text-center m-2 text-center text-black">
          {productTemplateInfo.description}
        </h2>
      </div>
      <div class="images-div m-8 p-4">
        <h3 class="img-heading text-left">Recent Images:</h3>
        <div class="imagediv flex m-4 justify-center align-center">
          <img src="{productTemplateInfo.image_url}" alt="" />
        </div>
      </div>
    </div>
  </div>
</div>

<style windi:inject>
:global(.qrcode) {
  margin: 0 auto !important;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-full {
  border-radius: 9999px;
}
.border-none {
  border-style: none;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.font-mono {
  font-family: ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
}
.font-bold {
  font-weight: 700;
}
.font-semibold {
  font-weight: 600;
}
.font-normal {
  font-weight: 400;
}
.h-auto {
  height: auto;
}
.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.-m-16 {
  margin: -4rem;
}
.m-8 {
  margin: 2rem;
}
.m-4 {
  margin: 1rem;
}
.m-2 {
  margin: 0.5rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mt-16 {
  margin-top: 4rem;
}
.-ml-21 {
  margin-left: -5.25rem;
}
.min-w-0 {
  min-width: 0px;
}
.p-28 {
  padding: 7rem;
}
.p-4 {
  padding: 1rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.pb-4 {
  padding-bottom: 1rem;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.shadow-xl {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 20px 25px -5px rgba(var(--tw-shadow-color), 0.1), 0 10px 10px -5px rgba(var(--tw-shadow-color), 0.04);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-2xl {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 25px 50px -12px rgba(var(--tw-shadow-color), 0.25);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.ring-offset-gray-50 {
  --tw-ring-offset-opacity: 1;
  --tw-ring-offset-color: rgba(249, 250, 251, var(--tw-ring-offset-opacity));
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-red-400 {
  --tw-text-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-text-opacity));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}
.align-middle {
  vertical-align: middle;
}
.break-words {
  overflow-wrap: break-word;
}
.w-full {
  width: 100%;
}
</style>
